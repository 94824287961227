import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '../utils';

@JsonObject('DeleteApiKeyRequest')
export class DeleteApiKeyRequest extends RequestCommon {
  @JsonProperty('id', String)
  id: string;
}

type TConstructorParams = {
  id: DeleteApiKeyRequest['id'];
};

export function constructDeleteApiKeyRequest({ id }: TConstructorParams): DeleteApiKeyRequest {
  const instance = new DeleteApiKeyRequest();
  instance.id = id;

  return instance;
}
