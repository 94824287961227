import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BackspaceIcon from 'src/components/shared/icons/BackspaceIcon';
import { useFeedbackEngineStore } from '../store/FeedbackEngineStore';

type TClearMyFeedbackButtonProps = {
  isDisabled: boolean;
  handleClear: () => void;
};

function ClearMyFeedbackButton({ isDisabled, handleClear }: TClearMyFeedbackButtonProps) {
  const { t } = useTranslation(['feedback']);
  const { palette } = useTheme();
  const isClearSelected = useFeedbackEngineStore((store) => store.isClearSelected);
  const isOverwriteMode = useFeedbackEngineStore((store) => store.isOverwriteMode);

  return (
    <Button
      size="small"
      variant="tertiary"
      endIcon={<BackspaceIcon />}
      sx={{ flexShrink: 0, borderColor: isClearSelected && !isDisabled ? palette.info.main : palette.secondary[600] }}
      onClick={handleClear}
      disabled={isDisabled}
    >
      {isOverwriteMode ? 'Clear consensus overwrite' : t('feedback:clearMyFeedback')}
    </Button>
  );
}

export default ClearMyFeedbackButton;
