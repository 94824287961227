import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiButtonStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiButton: {
    styleOverrides: {
      sizeSmall: {
        padding: '0 16px',
        fontSize: '14px',
        height: '36px',

        '& .MuiButton-endIcon > *': {
          fontSize: 'initial'
        },

        '& .MuiSvgIcon-root': {
          fontSize: '16px'
        },

        '&.MuiButton-root--icon': {
          width: '36px',
          height: '36px',
          minWidth: 'unset'
        }
      },
      sizeMedium: {
        padding: '14px 16px',
        fontSize: '14px',
        lineHeight: '16px'
      },
      sizeLarge: {
        padding: '16px 24px',
        minHeight: '52px',
        fontSize: '16px'
      },
      containedPrimary: {
        border: `1px solid ${palette.primary.main}`,
        ':disabled': {
          borderColor: palette.secondary[300],
          backgroundColor: palette.secondary[300],
          color: palette.secondary[500]
        }
      },
      containedSecondary: {
        border: `1px solid ${palette.secondary[600]}`,
        backgroundColor: palette.secondary[800],
        color: palette.secondary[200],
        ':hover': {
          backgroundColor: palette.secondary[1000]
        },
        ':disabled': {
          borderColor: palette.secondary[300],
          backgroundColor: palette.secondary[300],
          color: palette.secondary[500]
        }
      },
      outlinedSecondary: {
        border: `1px solid ${palette.secondary[600]}`,
        backgroundColor: palette.secondary[800],
        color: palette.secondary[300],
        ':hover': {
          backgroundColor: palette.secondary[1000]
        },
        ':disabled': {
          borderColor: palette.secondary[700],
          backgroundColor: 'unset',
          color: palette.secondary[500]
        }
      },
      containedWarning: {
        border: `1px solid ${palette.warning[400]}`,
        backgroundColor: palette.warning[400],
        color: palette.secondary[800],
        ':hover': {
          backgroundColor: palette.secondary[1000],
          color: palette.secondary[200]
        },
        ':disabled': {
          borderColor: palette.secondary[300],
          backgroundColor: palette.secondary[300],
          color: palette.secondary[500]
        }
      },
      text: {
        textDecoration: 'underline',
        padding: '0px 0px',
        minWidth: 'unset',
        textUnderlineOffset: '2px',
        ':hover': {
          textDecoration: 'underline'
        }
      },
      textSizeSmall: {
        padding: '4px'
      },
      textSizeLarge: {
        padding: '4px',
        fontSize: '16px',
        lineHeight: '16px',
        minHeight: 'unset'
      },
      textSizeMedium: {
        padding: '4px',
        fontSize: '14px',
        lineHeight: '16px'
      },
      textSecondary: {
        color: palette.secondary[300],
        ':hover': {
          color: palette.secondary[100]
        },
        ':disabled': {
          color: palette.secondary[500]
        }
      },
      root: {
        lineHeight: 1,
        textTransform: 'none',
        fontWeight: 600,

        '.spinner': {
          margin: '-4px -4px -4px 8px'
        }
      }
    },
    variants: [
      {
        props: { variant: 'containedHover' },
        style: {
          border: `1px solid ${palette.secondary[600]}`,
          color: palette.secondary[200],
          backgroundColor: palette.secondary[1000]
        }
      },
      {
        props: { variant: 'tertiary' },
        style: {
          border: `1px solid ${palette.secondary[600]}`,
          backgroundColor: 'unset',
          color: palette.secondary[300],
          ':hover': {
            backgroundColor: palette.secondary[1000]
          },
          ':disabled': {
            borderColor: palette.secondary[700],
            backgroundColor: 'unset',
            color: palette.secondary[500]
          }
        }
      }
    ]
  }
});
