import { Button, Stack } from '@mui/material';
import { TAdditionalFilter, TFilterItem } from '../../AdvancedFilters.types';
import FilterChip from './components/FilterChip';
import { TOption } from '@arcanna/generic';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { useTranslation } from 'react-i18next';
import StyledFilterChip from './components/FilterChip/StyledFilterChip.styles';
import { Icon, EIcon } from '@arcanna/generic';
import { useMemo } from 'react';

type TAdvancedFiltersListProps = {
  filters: TFilterItem[];
  fieldOptions: (FeedbackFiltersFieldsRecord & TOption)[];
  operatorOptionsMap: Record<string, TOption[]>;
  onDelete: (filterId: string) => void;
  onUpdate: (filter: TFilterItem) => void;
  onClearAll: () => void;
  getValueOptions: (field: string) => Promise<TOption[]>;
  additionalFilters?: TAdditionalFilter[];
};

function AdvancedFiltersList({
  filters,
  fieldOptions,
  operatorOptionsMap,
  onDelete,
  onUpdate,
  onClearAll,
  getValueOptions,
  additionalFilters = []
}: TAdvancedFiltersListProps) {
  const { t } = useTranslation('common');

  const isClearAllEnabled = useMemo(
    () => filters.some((filterItem) => !filterItem.isDeleteDisabled) || Boolean(additionalFilters.length),
    [additionalFilters.length, filters]
  );

  return Boolean(filters.length) || Boolean(additionalFilters.length) ? (
    <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
      {isClearAllEnabled && (
        <Stack alignItems="center" marginRight={1}>
          <Button color="secondary" onClick={onClearAll}>
            {t('clear')}
          </Button>
        </Stack>
      )}
      {additionalFilters.map((filter) => (
        <StyledFilterChip
          key={filter.label}
          label={filter.label}
          onDelete={filter.onDelete}
          deleteIcon={<Icon name={EIcon.ChipClose} />}
          status="active"
        />
      ))}
      {filters.map((filter) => (
        <FilterChip
          key={filter.id}
          filter={filter}
          fieldOptions={fieldOptions}
          operatorOptionsMap={operatorOptionsMap}
          onDelete={onDelete}
          onUpdate={onUpdate}
          getValueOptions={getValueOptions}
        />
      ))}
    </Stack>
  ) : (
    <></>
  );
}

export default AdvancedFiltersList;
