import { EIcon, Icon } from '@arcanna/generic';
import { Stack, Tooltip, TooltipProps, Typography, TypographyProps, useTheme } from '@mui/material';

type TLabelProps = {
  text: string;
  textProps?: TypographyProps;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
  required?: boolean;
  marginBottom?: string | number;
};

function Label({ text, textProps, tooltipText, tooltipPlacement, required, marginBottom }: TLabelProps) {
  const { palette } = useTheme();

  return (
    <Stack spacing="8px" direction="row" alignItems="center" sx={{ pointerEvents: 'all', mb: marginBottom }}>
      <Stack spacing="2px" direction="row">
        <Typography variant="subtitle1" {...textProps}>
          {text}
        </Typography>
        {required && (
          <Typography component="span" variant="subtitle1" color={palette.error.main}>
            *
          </Typography>
        )}
      </Stack>
      {tooltipText && (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: tooltipText }}></div>} placement={tooltipPlacement} arrow>
          <Stack component="span" direction="row" alignItems="center" justifyContent="center" color={palette.secondary[400]}>
            <Icon name={EIcon.InfoCircle} fontSize="small" />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
}

export default Label;
