/* eslint-disable max-len */
function Settings02() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.26344 12.914L6.65307 13.7903C6.7689 14.0511 6.95792 14.2728 7.19722 14.4283C7.43652 14.5839 7.71581 14.6666 8.00122 14.6666C8.28663 14.6666 8.56592 14.5839 8.80522 14.4283C9.04452 14.2728 9.23354 14.0511 9.34937 13.7903L9.739 12.914C9.8777 12.6031 10.111 12.3438 10.4057 12.1733C10.7022 12.0022 11.0452 11.9294 11.3857 11.9651L12.339 12.0666C12.6228 12.0966 12.9092 12.0436 13.1635 11.9141C13.4177 11.7846 13.629 11.5841 13.7716 11.337C13.9144 11.0899 13.9824 10.8068 13.9674 10.5218C13.9524 10.2369 13.8551 9.96245 13.6871 9.73177L13.1227 8.95622C12.9217 8.67801 12.8143 8.34313 12.816 7.99992C12.816 7.65764 12.9244 7.32415 13.1257 7.04733L13.6901 6.27177C13.858 6.04109 13.9554 5.76663 13.9704 5.4817C13.9854 5.19677 13.9173 4.91361 13.7746 4.66659C13.632 4.41941 13.4207 4.21891 13.1664 4.0894C12.9121 3.9599 12.6257 3.90694 12.342 3.93696L11.3886 4.03844C11.0482 4.07419 10.7052 4.00133 10.4086 3.83029C10.1134 3.65875 9.88001 3.39816 9.74196 3.08584L9.34937 2.20955C9.23354 1.9487 9.04452 1.72706 8.80522 1.57152C8.56592 1.41597 8.28663 1.33321 8.00122 1.33325C7.71581 1.33321 7.43652 1.41597 7.19722 1.57152C6.95792 1.72706 6.7689 1.9487 6.65307 2.20955L6.26344 3.08584C6.1254 3.39816 5.89202 3.65875 5.59678 3.83029C5.30025 4.00133 4.95722 4.07419 4.61678 4.03844L3.66048 3.93696C3.3767 3.90694 3.09031 3.9599 2.83602 4.0894C2.58174 4.21891 2.37049 4.41941 2.22789 4.66659C2.0851 4.91361 2.01708 5.19677 2.03207 5.4817C2.04705 5.76663 2.14441 6.04109 2.31233 6.27177L2.87678 7.04733C3.07807 7.32415 3.18647 7.65764 3.18641 7.99992C3.18647 8.34219 3.07807 8.67569 2.87678 8.95251L2.31233 9.72807C2.14441 9.95875 2.04705 10.2332 2.03207 10.5181C2.01708 10.8031 2.0851 11.0862 2.22789 11.3333C2.37063 11.5803 2.58191 11.7807 2.83615 11.9102C3.0904 12.0397 3.37672 12.0927 3.66048 12.0629L4.61381 11.9614C4.95426 11.9256 5.29729 11.9985 5.59381 12.1695C5.89017 12.3406 6.12462 12.6012 6.26344 12.914Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00003 9.99992C9.1046 9.99992 10 9.10449 10 7.99992C10 6.89535 9.1046 5.99992 8.00003 5.99992C6.89546 5.99992 6.00003 6.89535 6.00003 7.99992C6.00003 9.10449 6.89546 9.99992 8.00003 9.99992Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Settings02;
