import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';

type TGetLabelClearSelectedParams = {
  currentJobInfoLabels: CustomLabelByJob[];
  currentLabelId: string | null;
  isOverwriteMode: boolean;
  hasConsensusOverwritten: boolean;
};

type TGetLabelClearReturnType = {
  newLabel: CustomLabelType | undefined;
  isNewLabelOverwritten: boolean;
};

// Case when clear is selected
export function getLabelClearSelected({
  currentJobInfoLabels,
  currentLabelId,
  isOverwriteMode,
  hasConsensusOverwritten
}: TGetLabelClearSelectedParams): TGetLabelClearReturnType {
  // If we are not in overwrite mode we simply do not show the newLabel
  if (!isOverwriteMode && !hasConsensusOverwritten)
    return {
      newLabel: undefined,
      isNewLabelOverwritten: false
    };

  if (!isOverwriteMode && hasConsensusOverwritten) {
    const newLabel = currentJobInfoLabels.find((customLabel) => customLabel.id === currentLabelId);
    return {
      newLabel,
      isNewLabelOverwritten: true
    };
  }

  if (isOverwriteMode && !hasConsensusOverwritten) {
    const newLabel = currentJobInfoLabels.find((customLabel) => customLabel.id === currentLabelId);
    return {
      newLabel,
      isNewLabelOverwritten: false
    };
  }

  if (isOverwriteMode && hasConsensusOverwritten) {
    const newLabel = currentJobInfoLabels.find((customLabel) => customLabel.id === currentLabelId);
    return {
      newLabel,
      isNewLabelOverwritten: false
    };
  }

  return {
    newLabel: undefined,
    isNewLabelOverwritten: false
  };
}

type TGetLabelConfirmSelectedParams = {
  currentJobInfoLabels: CustomLabelByJob[];
  arcannaLabelId: string | null;
  currentLabelId: string | null;
  isOverwriteMode: boolean;
  hasConsensusOverwritten: boolean;
};

type TGetLabelConfirmSelectedReturnType = {
  newLabel: CustomLabelType | undefined;
  isNewLabelOverwritten: boolean;
};

export function getLabelConfirmSelected({
  currentJobInfoLabels,
  arcannaLabelId,
  currentLabelId,
  isOverwriteMode,
  hasConsensusOverwritten
}: TGetLabelConfirmSelectedParams): TGetLabelConfirmSelectedReturnType {
  // log the params
  if (!isOverwriteMode && !hasConsensusOverwritten) {
    return {
      newLabel: currentJobInfoLabels.find((customLabel) => customLabel.id === arcannaLabelId),
      isNewLabelOverwritten: false
    };
  }

  if (!isOverwriteMode && hasConsensusOverwritten) {
    const newLabel = currentJobInfoLabels.find((customLabel) => customLabel.id === currentLabelId);
    return {
      newLabel,
      isNewLabelOverwritten: true
    };
  }

  if (isOverwriteMode) {
    const newLabel = currentJobInfoLabels.find((customLabel) => customLabel.id === arcannaLabelId);
    return {
      newLabel,
      isNewLabelOverwritten: true
    };
  }

  return {
    newLabel: currentJobInfoLabels.find((customLabel) => customLabel.id === arcannaLabelId),
    isNewLabelOverwritten: false
  };
}

type TGetMatchingLabelParams = {
  currentJobInfoLabels: CustomLabelByJob[];
  currentLabelId: string | null;
  selectedLabel?: CustomLabelType | null;
  isOverwriteMode: boolean;
  hasConsensusOverwritten: boolean;
};

type TGetMatchingLabelReturnType = {
  newLabel: CustomLabelType | undefined;
  isNewLabelOverwritten: boolean;
};

export function getMatchingLabel({
  currentJobInfoLabels,
  currentLabelId,
  selectedLabel,
  isOverwriteMode,
  hasConsensusOverwritten
}: TGetMatchingLabelParams): TGetMatchingLabelReturnType {
  if (currentLabelId === undecidedConsensus.id && !selectedLabel) {
    return {
      newLabel: undecidedConsensus,
      isNewLabelOverwritten: false
    };
  }

  const value: CustomLabelType | undefined = currentJobInfoLabels.find(
    (customLabel) =>
      // name and id must concide when a label is selected
      (selectedLabel && customLabel.id === selectedLabel?.id && selectedLabel.name == customLabel.name) ||
      // otherwise just displayed the new label retrieved by API (the one previously saved)
      (!selectedLabel && customLabel.id === currentLabelId)
  );

  if (isOverwriteMode && hasConsensusOverwritten && selectedLabel && value) {
    return {
      newLabel: value,
      isNewLabelOverwritten: true
    };
  }

  if (!isOverwriteMode && hasConsensusOverwritten && selectedLabel) {
    return {
      newLabel: currentJobInfoLabels.find((customLabel) => customLabel.id === currentLabelId),
      isNewLabelOverwritten: true
    };
  }

  if (isOverwriteMode && !hasConsensusOverwritten && selectedLabel && value) {
    return {
      newLabel: value,
      isNewLabelOverwritten: true
    };
  }

  if (selectedLabel && value) {
    return {
      newLabel: value,
      isNewLabelOverwritten: hasConsensusOverwritten
    };
  }

  return {
    newLabel:
      currentLabelId === undecidedConsensus.id
        ? undecidedConsensus
        : currentJobInfoLabels.find((customLabel) => customLabel.id === currentLabelId),
    isNewLabelOverwritten: hasConsensusOverwritten
  };
}
