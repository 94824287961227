import { Palette } from '@mui/material/styles';
import { TFunction } from 'i18next';
import { EIcon } from '@arcanna/generic';

const CONFIDENCE_SCORE_THRESHOLDS = {
  LOW: 50,
  MEDIUM: 75
};

const getConfidenceScoreColor = (palette: Palette, score: number) => {
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.LOW) return palette.error[400];
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.MEDIUM) return palette.warning[400];
  return palette.success[300];
};

const getConfidenceScoreLabel = (t: TFunction, score: number) => {
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.LOW) return t('feedback:confidenceScoreLow');
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.MEDIUM) return t('feedback:confidenceScoreMedium');
  return t('feedback:confidenceScoreHigh');
};

const getIconName = (isAttentionRequired: boolean, isConfidenceScoreMedium: boolean, isConfidenceScoreHigh: boolean) => {
  if (isAttentionRequired) {
    return EIcon.AlertTriangle;
  }
  if (isConfidenceScoreMedium) {
    return EIcon.AlertTriangle;
  }
  if (isConfidenceScoreHigh) {
    return EIcon.FaceSmile;
  }

  return null;
};

export { CONFIDENCE_SCORE_THRESHOLDS, getConfidenceScoreColor, getConfidenceScoreLabel, getIconName };
