import { Spinner, StateChip } from '@arcanna/generic';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function JobCollectingDataTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return (
    <StateChip
      color={theme.palette.grey[400]}
      text={t('job:jobState.collectingData')}
      dataTestId={'collecting-data'}
      icon={<Spinner />}
    />
  );
}
