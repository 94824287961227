import { CreateApiKeyRequest, CreateApiKeyResponse } from '@arcanna/models/ApiKeys';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiKeysKeys } from 'src/_srcMUI/requests/ApiKeys/keys';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

function useCreateApiKey() {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.apiKeys.create, []);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<CreateApiKeyResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, CreateApiKeyResponse)),
    [URL]
  );

  return useMutation(
    apiKeysKeys.create(),
    (payload: CreateApiKeyRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiKeysKeys.list());
        showSuccessNotification(
          t('requests:apiKeys.createApiKey.success.title'),
          t('requests:apiKeys.createApiKey.success.subtitle')
        );
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error?.data?.resource?.request?.reason) {
          showErrorNotification(t('requests:apiKeys.createApiKey.error.title'), error.data.resource.request.reason);
        } else {
          showErrorNotification(
            t('requests:apiKeys.createApiKey.error.title'),
            t('requests:apiKeys.createApiKey.error.subtitle')
          );
        }
      }
    }
  );
}

export default useCreateApiKey;
