import { Stack, TableCell as MuiTableCell, IconButton, useTheme } from '@mui/material';
import { Row as TanstackRow } from '@tanstack/react-table';
import { Icon, EIcon, Checkbox } from '@arcanna/generic';
import { TABLE_SIZES } from '../TableSizeSelector/TableSizeSelector.constants';
import TableBodyCell from '../TableBodyCell';
import { getCheckboxState } from './TableBody.utils';
import StyledRow from './StyledRow.styles';
import { ReactNode } from 'react';

function Row<T>({
  row,
  index,
  emphasizedRows,
  onRowClick,
  tableSize,
  isRowSelectionEnabled = true,
  isActionsColumnVisible,
  actionsColumnSize,
  isStrippedTable,
  getIsRowEmphasized,
  customRowSizeValue,
  renderSubComponent,
  getIsRowDisabled
}: {
  row: TanstackRow<T>;
  index: number;
  emphasizedRows: number[];
  onRowClick?: (row: TanstackRow<T>, index: number) => void;
  isRowSelectionEnabled: boolean | undefined;
  isActionsColumnVisible: boolean;
  tableSize: string;
  actionsColumnSize: number;
  isStrippedTable: boolean;
  getIsRowEmphasized?: (row: TanstackRow<T>) => boolean;
  customRowSizeValue: number;
  renderSubComponent?: ({ row }: { row: TanstackRow<T> }) => ReactNode;
  getIsRowDisabled: (row: TanstackRow<T>) => boolean;
}) {
  const theme = useTheme();
  const isRowEmphasized = emphasizedRows.includes(index) || Boolean(getIsRowEmphasized?.(row));

  return (
    <>
      <StyledRow
        tableSize={tableSize}
        isHighlighted={row.getIsSelected() || isRowEmphasized}
        key={row.id}
        isStrippedTable={isStrippedTable}
        isDisabled={getIsRowDisabled(row)}
      >
        {isActionsColumnVisible && (
          <MuiTableCell
            component="td"
            className="row-cell"
            sx={{
              width: `${actionsColumnSize}px`,
              maxWidth: `${actionsColumnSize}px`
            }}
          >
            <Stack direction="row" alignItems="center" gap={2} sx={{ paddingY: tableSize === TABLE_SIZES.small ? 0 : '10px' }}>
              {isRowSelectionEnabled && (
                <Checkbox
                  state={getCheckboxState(row as TanstackRow<unknown>)}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(_, event) => {
                    row.getToggleSelectedHandler()(event);
                  }}
                  disabled={getIsRowDisabled(row)}
                  sx={{ marginRight: 0, marginLeft: 0 }}
                />
              )}
              {onRowClick && (
                <IconButton
                  variant="contained"
                  color="secondary"
                  className="row-expand-button"
                  onClick={() => onRowClick(row, index)}
                >
                  <Icon name={isRowEmphasized ? EIcon.Minimize01 : EIcon.Expand01} sx={{ fontSize: '12px' }} />
                </IconButton>
              )}
            </Stack>
          </MuiTableCell>
        )}

        {row.getVisibleCells().map((cell) => (
          <TableBodyCell<T>
            actionsColumnSize={actionsColumnSize}
            key={cell.id}
            column={cell.column}
            cellContext={cell.getContext()}
            tableSize={tableSize}
            customRowSizeValue={customRowSizeValue}
          />
        ))}
      </StyledRow>
      {row.getIsExpanded() && (
        <div style={{ display: 'table-row', borderBottom: `1px solid ${theme.palette.secondary[700]}` }}>
          <td colSpan={row.getVisibleCells().length}>{renderSubComponent?.({ row })}</td>
        </div>
      )}
    </>
  );
}

export default Row;
