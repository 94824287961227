export type TFilterOperator = EFilterOperatorWithValue | EFilterOperatorWithoutValue | EFilterOperatorMultiple;

export enum EFilterOperatorMultiple {
  isOneOf = 'is one of',
  isNotOneOf = 'is not one of'
}

export enum EFilterOperatorWithValue {
  is = 'is',
  isNot = 'is not',
  startsWith = 'starts with',
  notStartsWith = 'not starts with',
  contains = 'contains',
  notContains = 'not contains'
}

export enum EFilterOperatorWithoutValue {
  exists = 'exists',
  notExists = `doesn't exist`
}

export const FILTER_OPPOSITES_MAP = {
  [EFilterOperatorWithValue.is]: [EFilterOperatorWithValue.isNot],
  [EFilterOperatorWithValue.isNot]: [EFilterOperatorWithValue.is],
  [EFilterOperatorWithValue.startsWith]: [EFilterOperatorWithValue.notStartsWith],
  [EFilterOperatorWithValue.notStartsWith]: [EFilterOperatorWithValue.startsWith],
  [EFilterOperatorWithValue.contains]: [EFilterOperatorWithValue.notContains],
  [EFilterOperatorWithValue.notContains]: [EFilterOperatorWithValue.contains]
};

type TFilterBaseItem = {
  id: string;
  field: string;
  label?: string;
  valueLabel?: string;
  status: 'active' | 'disabled';
  isDeleteDisabled?: boolean;
};

export type TFilterItemWithValue = TFilterBaseItem & {
  operator: EFilterOperatorWithValue | EFilterOperatorMultiple;
  value: string | string[];
};

type TFilterItemWithoutValue = TFilterBaseItem & {
  operator: EFilterOperatorWithoutValue;
};

export type TFilterItemWithValueType = TFilterItemWithValue & {
  valueType: string;
};

export type TFilterItem = TFilterItemWithValue | TFilterItemWithoutValue | TFilterItemWithValueType;

export type TAdditionalFilter = {
  label: string;
  onDelete: () => void;
};
