import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('CreateApiKeyResponse')
export class CreateApiKeyResponse extends ResponseCommon {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', String)
  value: string;

  @JsonProperty('created_at', String)
  createdAt: string;
}
