import { Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircledCheckmark from 'src/components/shared/icons/CircledCheckmark';
import { useFeedbackEngineStore } from '../store/FeedbackEngineStore';

type TConfirmArcannaDecisionButtonProps = {
  isDisabled: boolean;
  handleConfirm: () => void;
};

function ConfirmArcannaDecisionButton({ isDisabled, handleConfirm }: TConfirmArcannaDecisionButtonProps) {
  const { t } = useTranslation(['feedback']);
  const { palette } = useTheme();
  const isConfirmSelected = useFeedbackEngineStore((store) => store.isConfirmSelected);

  return (
    <Button
      size="small"
      color="secondary"
      variant="outlined"
      disabled={isDisabled}
      endIcon={<CircledCheckmark />}
      onClick={handleConfirm}
      sx={{
        minWidth: '250px',
        borderColor: isConfirmSelected && !isDisabled ? palette.info.main : palette.secondary[600]
      }}
    >
      {t('feedback:confirmDecisionsUnified')}
    </Button>
  );
}

export default ConfirmArcannaDecisionButton;
