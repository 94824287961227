import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '../utils';

@JsonObject('CreateApiKeyRequest')
export class CreateApiKeyRequest extends RequestCommon {
  @JsonProperty('name', String)
  name: string;
}

type TConstructorParams = {
  name: CreateApiKeyRequest['name'];
};

export function constructCreateApiKeyRequest({ name }: TConstructorParams): CreateApiKeyRequest {
  const instance = new CreateApiKeyRequest();
  instance.name = name;

  return instance;
}
