import { Box, BoxProps, styled } from '@mui/material';

const StyledFeedbackEngineSingleBar = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary[900],
  border: `1px solid ${theme.palette.secondary[700]}`,
  borderBottom: 0,
  borderRadius: '4px 4px 0 0',
  position: 'relative',

  '.customLabel': {
    color: theme.palette.secondary[100],
    width: '136px',
    maxHeight: '36px',
    border: `1px solid ${theme.palette.secondary[600]}`
  }
}));

export default StyledFeedbackEngineSingleBar;
