/* eslint-disable max-len */
function Colors() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_16559_1862)">
        <path
          d="M8.00016 13.6481C8.70783 14.2815 9.64235 14.6667 10.6668 14.6667C12.876 14.6667 14.6668 12.8758 14.6668 10.6667C14.6668 8.82205 13.4182 7.26905 11.72 6.80678M4.28028 6.80677C2.58212 7.26905 1.3335 8.82204 1.3335 10.6667C1.3335 12.8758 3.12436 14.6667 5.3335 14.6667C7.54264 14.6667 9.3335 12.8758 9.3335 10.6667C9.3335 10.1463 9.23414 9.64921 9.05338 9.19322M12.0002 5.33333C12.0002 7.54247 10.2093 9.33333 8.00016 9.33333C5.79102 9.33333 4.00016 7.54247 4.00016 5.33333C4.00016 3.12419 5.79102 1.33333 8.00016 1.33333C10.2093 1.33333 12.0002 3.12419 12.0002 5.33333Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16559_1862">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Colors;
