import { CustomLabel } from '@arcanna/components';
import { EIcon, Icon } from '@arcanna/generic';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { Autocomplete, Box, ClickAwayListener, InputAdornment, Stack, TextField, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showInfoNotification } from 'src/components/shared/utilities/notification';
import AddCustomLabelButton from '../../Buttons/AddCustomLabelButton';
import { isDecisionIntelligenceCategory } from '../../../../../components/pages/Main/Jobs/helper';

const MAX_WIDTH = 1920;

type TSelectCustomLabelAreaProps = {
  jobId?: number;
  jobCategory?: number;
  customLabels: CustomLabelByJob[];
  selectedLabel: CustomLabelByJob | null;
  onSelect: (customLabel: CustomLabelByJob | null) => void;
  limitShownLabels?: number;
  isDisabled?: boolean;
};

function SelectCustomLabelArea({
  jobId,
  jobCategory,
  customLabels,
  selectedLabel,
  onSelect,
  limitShownLabels,
  isDisabled
}: TSelectCustomLabelAreaProps) {
  const { t } = useTranslation(['feedback']);
  const { palette } = useTheme();
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [open, setOpen] = useState(false);

  useEffect(function updateWindowWidthState() {
    const updateWindowDimensions = () => {
      const currentWidth = window.innerWidth;
      setWindowWidth(currentWidth);
    };

    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const numButtons = useMemo(
    () =>
      limitShownLabels !== undefined
        ? limitShownLabels
        : Math.max(Math.floor((Math.min(MAX_WIDTH, windowWidth) - 1150) / 136), 0),
    [limitShownLabels, windowWidth]
  );
  const buttonCustomLabels: CustomLabelByJob[] = useMemo(() => customLabels.slice(0, numButtons), [customLabels, numButtons]);
  const selectCustomLabels: CustomLabelByJob[] = useMemo(() => customLabels.slice(numButtons), [customLabels, numButtons]);
  const allDecisionsText = t('feedback:allDecisions').replace('NUMBER_OF_LABELS', customLabels.length.toString());

  const convertCustomLabelToObj = useCallback(
    (labelInfo: CustomLabelByJob | null) => {
      if (labelInfo != null) {
        return {
          label: labelInfo.name,
          value: labelInfo.id,
          hexColor: labelInfo.hexColor,
          jobIds: labelInfo.jobIds,
          groupName: allDecisionsText
        };
      }
      return null;
    },
    [allDecisionsText]
  );

  const selectedLabelCompleteInfo = useMemo(
    () => convertCustomLabelToObj(selectedLabel),
    [selectedLabel, convertCustomLabelToObj]
  );

  const labelSelected = useCallback(
    (customLabel: CustomLabelByJob | null) => {
      onSelect(customLabel);
      if (customLabel != null) {
        showInfoNotification(t('feedback:decisionSelected'), t('feedback:namedLabel').replace('LABEL', customLabel.name));
      }
    },
    [onSelect, t]
  );

  const displayAddCustomLabel = isDecisionIntelligenceCategory(jobCategory);

  return (
    <Stack direction="row" gap="8px">
      {buttonCustomLabels.map((customLabel) => (
        <CustomLabel
          key={customLabel.id}
          id={customLabel.id}
          hexColor={customLabel.hexColor}
          name={customLabel.name}
          hasBorder
          disabledTooltip={false}
          disabledTooltipOnOverflow={true}
          showBorderOnlyOnHover
          className="customLabel"
          onClick={() => {
            labelSelected(customLabel);
          }}
          dataTestId={`${customLabel?.name}-feedback-button`}
          width={160}
          disabled={isDisabled}
          isHighlighted={selectedLabel?.id === customLabel.id && selectedLabel?.jobIds === customLabel.jobIds}
        />
      ))}
      {displayAddCustomLabel && jobId && selectCustomLabels.length === 0 && <AddCustomLabelButton jobId={jobId} shrinkedView />}
      {selectCustomLabels.length > 0 && (
        <Autocomplete
          disablePortal
          open={open}
          onOpen={() => setOpen(true)}
          id="select-feedback"
          data-test-id="select-feedback"
          onChange={(_, customLabel) => {
            const newLabel = customLabel
              ? new CustomLabelByJob(customLabel.value, customLabel.label, customLabel.hexColor, customLabel.jobIds)
              : null;
            labelSelected(newLabel);
            setOpen(false);
          }}
          sx={{ width: '200px', opacity: isDisabled ? 0.7 : 1, pointerEvents: isDisabled ? 'none' : 'auto' }}
          size="small"
          value={selectedLabelCompleteInfo}
          // eslint-disable-next-line
          options={customLabels.map((customLabel) => convertCustomLabelToObj(customLabel)!)}
          renderOption={(props, option) => (
            <Box padding="16px" component="li" {...props}>
              <CustomLabel name={option.label} hexColor={option.hexColor} />
            </Box>
          )}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }

            return option.label;
          }}
          groupBy={(option) => option.groupName}
          autoComplete
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={allDecisionsText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <Icon name={EIcon.ActionSearch} />
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          PaperComponent={({ children }) => (
            <ClickAwayListener
              onClickAway={(event) => {
                const button = document.getElementById('select-feedback');
                // @ts-ignore
                if (button && button.contains(event.target)) {
                  return;
                }
                setOpen(false);
              }}
            >
              <Box
                sx={{
                  border: `1px solid ${palette.info[600]}`,
                  borderRadius: '4px !important',
                  backgroundColor: palette.secondary[900],
                  '& .MuiAutocomplete-listbox': {
                    border: 0
                  }
                }}
              >
                {children}
                {displayAddCustomLabel && jobId && (
                  <Box sx={{ borderTop: '1px solid', borderColor: palette.secondary[700], p: 1 }}>
                    <AddCustomLabelButton jobId={jobId} />
                  </Box>
                )}
              </Box>
            </ClickAwayListener>
          )}
        />
      )}
    </Stack>
  );
}

export default SelectCustomLabelArea;
