/* eslint-disable max-len */
function Tool() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path
        d="M10.8209 5.0875C10.5569 4.82349 10.4249 4.69148 10.3754 4.53926C10.3319 4.40537 10.3319 4.26114 10.3754 4.12724C10.4249 3.97502 10.5569 3.84302 10.8209 3.579L12.7131 1.68676C12.211 1.45967 11.6536 1.33325 11.0666 1.33325C8.8575 1.33325 7.06664 3.12411 7.06664 5.33325C7.06664 5.66061 7.10596 5.97879 7.18015 6.28331C7.25959 6.60941 7.29931 6.77246 7.29225 6.87547C7.28487 6.98331 7.26879 7.04069 7.21906 7.13666C7.17156 7.22833 7.08054 7.31935 6.89852 7.50137L2.73331 11.6666C2.18102 12.2189 2.18102 13.1143 2.73331 13.6666C3.28559 14.2189 4.18102 14.2189 4.73331 13.6666L8.89852 9.50137C9.08054 9.31935 9.17156 9.22833 9.26323 9.18083C9.3592 9.1311 9.41658 9.11502 9.52442 9.10764C9.62743 9.10058 9.79048 9.1403 10.1166 9.21974C10.4211 9.29393 10.7393 9.33325 11.0666 9.33325C13.2758 9.33325 15.0666 7.54239 15.0666 5.33325C15.0666 4.74631 14.9402 4.1889 14.7131 3.68676L12.8209 5.579C12.5569 5.84302 12.4249 5.97502 12.2727 6.02448C12.1388 6.06799 11.9945 6.06799 11.8606 6.02448C11.7084 5.97502 11.5764 5.84302 11.3124 5.579L10.8209 5.0875Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Tool;
