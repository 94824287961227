import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { eventKeys } from './keys';
import { FeedbackEventRequest } from '@arcanna/models/Event/FeedbackEventRequest';
import { jobInfoKey } from 'src/data-access';
import _ from 'lodash';

function useFeedback() {
  // OTHER HOOKS
  const queryClient = useQueryClient();

  // SETUP
  // ADD
  const URL_ADD = config.api.feedback.add;
  const axiosFunctionAdd = useCallback((body: object) => axios.post<ResourceWrapper<ResponseCommon>>(URL_ADD, body), [URL_ADD]);

  // REMOVE
  const URL_REMOVE = config.api.feedback.remove;
  const axiosFunctionRemove = useCallback(
    (body: object) => axios.post<ResourceWrapper<ResponseCommon>>(URL_REMOVE, body),
    [URL_REMOVE]
  );

  // COMMON INVALIDATE QUERIES
  const invalidateQueries = useCallback(
    async (payload: FeedbackEventRequest) => {
      const jobIds = _.uniq(payload.updatesPerJob.map((updatePerJob) => updatePerJob.jobId));

      await Promise.all([
        // invalidate jobInfos for train button
        ...jobIds.map((jobId) => queryClient.invalidateQueries(jobInfoKey(jobId))),
        queryClient.invalidateQueries([config.api.isJobRetrainAvailable]),
        // ECDI
        queryClient.invalidateQueries(eventKeys.gets()),
        // RCA
        queryClient.invalidateQueries([config.api.eventExplorerBatch]),

        // FEEDBACK
        queryClient.invalidateQueries([config.api.feedbackBucketsBatch]),
        // FEEDBACK UNIFIED
        queryClient.invalidateQueries([config.api.feedbackBucketsUnifiedBatch])
      ]);
    },
    [queryClient]
  );

  // COMMON ERROR HANDLER
  const onError = useCallback((error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
    if (error?.data?.resource?.request?.reason) {
      showErrorNotification('Error', error.data.resource.request.reason);
    } else {
      showErrorNotification('Error', 'Something went wrong');
    }
  }, []);

  // MUTATIONS
  // ADD
  const addFeedbackMutation = useMutation(
    [config.api.feedback.add],
    (payload: FeedbackEventRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunctionAdd(payloadSerialized);
    },
    {
      onSuccess: async (_, payload) => invalidateQueries(payload),
      onError: onError
    }
  );

  // REMOVE
  const removeFeedbackMutation = useMutation(
    [config.api.feedback.remove],
    (payload: FeedbackEventRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunctionRemove(payloadSerialized);
    },
    {
      onSuccess: async (_, payload) => invalidateQueries(payload),
      onError: onError
    }
  );

  return { addFeedbackMutation, removeFeedbackMutation };
}

export default useFeedback;
