import { Chip, ChipProps, styled } from '@mui/material';

const StyledFilterChip = styled(Chip, {
  shouldForwardProp(propName) {
    return propName !== 'status';
  }
})<ChipProps & { status: 'active' | 'disabled' }>(({ theme, status }) => ({
  ...(status === 'disabled'
    ? {
        color: theme.palette.secondary[500],
        textDecoration: 'line-through',

        '&:hover': {
          textDecoration: 'line-through'
        }
      }
    : {}),

  paddingRight: '12px',

  '&.MuiChip-deletableColorDefault': {
    paddingRight: '6px'
  }
}));

export default StyledFilterChip;
