import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';
import { CustomLabel } from '../../../components/shared/models';

@JsonObject('AddCustomLabelRequest')
export class AddCustomLabelRequest extends RequestCommon {
  @JsonProperty('custom_label', CustomLabel)
  customLabel: CustomLabel;
}

type TConstructorParams = {
  customLabel: AddCustomLabelRequest['customLabel'];
};

export function constructAddCustomLabelRequest({ customLabel }: TConstructorParams) {
  const instance = new AddCustomLabelRequest();
  instance.customLabel = customLabel;

  return instance;
}
