import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMutation } from 'react-query';
import { IdeaBoxRequest } from 'src/components/shared/models/idea-box/IdeaBoxRequest';
import { config } from 'src/config';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';

function useIdeaBoxMutation() {
  const { t } = useTranslation(['idea-box']);

  return useMutation((payload: IdeaBoxRequest) => axios.post(config.api.makeAWish, payload), {
    onSuccess: () => {
      showSuccessNotification(t('idea-box:successMessage'), t('idea-box:successDescription'));
    },
    onError: () => {
      showErrorNotification(t('idea-box:errorMessage'), t('idea-box:genericErrorDescription'));
    }
  });
}

export default useIdeaBoxMutation;
