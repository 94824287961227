import { ReactNode, useEffect, useState } from 'react';
import { Spinner } from '../Spinner';

function TabComponentRendering({ children }: { children: ReactNode }) {
  const [isShown, setIsShown] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return <>{!isShown ? <Spinner isOverlay /> : children}</>;
}

export default TabComponentRendering;
