import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '@arcanna/models/utils';

export enum EEventTrainingStatus {
  NEW = 'new',
  PENDING = 'pending',
  RETRAIN = 'retrain',
  IN_MODEL = 'in_model'
}

export enum EReprocessingStatus {
  TO_REPROCESS = 'to_reprocess',
  REPROCESSING = 'in_progress',
  FINISHED = 'finished'
}

@JsonObject('BatchEventColumnRecord')
export class BatchEventColumnRecord {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', String)
  value: string | null;

  @JsonProperty('caption', String)
  caption: string;

  @JsonProperty('type', String)
  type: string;

  @JsonProperty('internal', Boolean)
  internal: boolean;
}

@JsonObject('EventUserLabelEntry')
export class EventUserLabelEntry {
  @JsonProperty('label', String)
  label: string;

  @JsonProperty('label_timestamp', String)
  label_timestamp: string;

  @JsonProperty('username', String)
  username: string;
}

@JsonObject('EventUserLabelEntries')
export class EventUserLabelEntries {
  @JsonProperty('entries', [EventUserLabelEntry])
  entries: EventUserLabelEntry[];
}

@JsonObject('BatchEventRecord')
export class BatchEventRecord {
  @JsonProperty('columns', [BatchEventColumnRecord])
  columns: BatchEventColumnRecord[];

  @JsonProperty('confidence_score', Number)
  confidenceScore: number;

  @JsonProperty('consensus', String)
  consensus: string | null;

  @JsonProperty('job_id', String)
  jobId: string;

  @JsonProperty('low_confidence_score', Boolean)
  lowConfidenceScore: boolean | null;

  @JsonProperty('consensus_overwritten', Boolean)
  consensusOverwritten: boolean | null;

  @JsonProperty('consensus_overwritten_by', String)
  consensusOverwrittenBy: string | null;

  @JsonProperty('consensus_overwritten_date', String)
  consensusOverwrittenDate: string | null;

  @JsonProperty('nn_label', String)
  label: string;

  @JsonProperty('reprocessing_status', String)
  reprocessingStatus: EReprocessingStatus;

  @JsonProperty('trained_with', String)
  trainedWith: string;

  @JsonProperty('outlier', Boolean)
  outlier: boolean | null;

  @JsonProperty('result_index', String)
  resultIndex: string;

  @JsonProperty('row_id', String)
  rowId: string;

  @JsonProperty('uses_last_model', Boolean)
  usesLastModel: boolean | null;

  @JsonProperty('training_status', String)
  trainingStatus: EEventTrainingStatus;

  @JsonProperty('user_labels', EventUserLabelEntries)
  userLabels: EventUserLabelEntries;
}

@JsonObject('GetEventsBatchResponse')
export class GetEventsBatchResponse extends ResponseCommon {
  @JsonProperty('events', [BatchEventRecord])
  events: BatchEventRecord[];

  @JsonProperty('dynamic_columns', [String])
  dynamicColumns: string[];

  @JsonProperty('total', Number)
  total: number;
}
