import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorNotification, showSuccessNotification } from '../../../components/shared/utilities/notification';
import { config } from '../../../config';
import { AddCustomLabelRequest } from '@arcanna/models/Jobs/AddCustomLabelRequest';
import { jobInfoKey } from '../../../data-access';

type TUseAddCustomLabel = {
  jobId: number;
};

function useAddCustomLabel({ jobId }: TUseAddCustomLabel) {
  // OTHER HOOKS
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.job.addCustomLabel({ jobId }), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<ResponseCommon>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, ResponseCommon)),
    [URL]
  );

  // QUERY
  return useMutation(
    [URL],
    (payload: AddCustomLabelRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);

      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async (_, params) => {
        showSuccessNotification('Success', `Custom label ${params.customLabel.name} added successfully.`);
        await queryClient.invalidateQueries(jobInfoKey(jobId));
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>, params) => {
        if (error.data?.resource?.request?.reason) {
          showErrorNotification('Error', error.data.resource.request.reason);
        } else {
          showErrorNotification('Error', `Failed to add custom label ${params.customLabel.name}.`);
        }
      }
    }
  );
}

export default useAddCustomLabel;
