import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AssignedIntegrationsRecord')
export class AssignedIntegrationsRecord {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('name', String)
  name: string;
}

@JsonObject('ApiKeysEntryRecord')
export class ApiKeysEntryRecord {
  @JsonProperty('_id', String)
  id: string;

  @JsonProperty('name', String)
  name: string;

  @JsonProperty('created_at', String)
  createdAt: string;

  @JsonProperty('assigned_count', Number)
  assignedCount: number;

  @JsonProperty('assigned_integrations', [AssignedIntegrationsRecord])
  assignedIntegrations: AssignedIntegrationsRecord[];
}

type TConstructorParams = {
  id: ApiKeysEntryRecord['id'];
  name: ApiKeysEntryRecord['name'];
  createdAt: ApiKeysEntryRecord['createdAt'];
};

export function constructApiKeysEntryRecord({ id, name, createdAt }: TConstructorParams): ApiKeysEntryRecord {
  const instance = new ApiKeysEntryRecord();
  instance.id = id;
  instance.name = name;
  instance.createdAt = createdAt;

  return instance;
}
