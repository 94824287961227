import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery, UseQueryOptions } from 'react-query';
import { EventExplorerBatchResponse } from '../../components/shared/models/event-explorer/EventExplorerBatchResponse';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { EventExplorerBatchRequest } from '../../components/shared/models/event-explorer/EventExplorerBatchRequest';
import { EventExplorerFiltersState } from '../../components/pages/JobEventExplorer/models/EventExplorerFilters';
import { getTransformedAdvancedFilters } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { AdvancedFilters, TFilterItem } from '@arcanna/components';
import { useJobInfo } from '../job';

type UseEventExplorerBatch = EventExplorerFiltersState & {
  jobId: number;
  queryOptions?: Omit<UseQueryOptions<EventExplorerBatchResponse, Error>, 'queryKey' | 'queryFn'>;
};

export function useEventExplorerBatch({
  jobId,
  tablePageSize,
  tablePage,
  startDate,
  endDate,
  tableSort,
  advancedFilters,
  queryOptions
}: UseEventExplorerBatch) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const jobInfoQuery = useJobInfo(Number(jobId));

  const jobInfoCustomLabels = React.useMemo(
    () => jobInfoQuery.data?.info?.advancedSettings?.customLabels ?? [],
    [jobInfoQuery.data?.info?.advancedSettings?.customLabels]
  );

  return useQuery<EventExplorerBatchResponse, Error>(
    [
      config.api.eventExplorerBatch,
      {
        jobId,
        tablePageSize,
        tablePage: (tablePage - 1) * tablePageSize,
        startDate: startDate?.toISOString(),
        endDate: endDate ? endDate.toISOString() : null,
        tableSort,
        advancedFilters
      }
    ],
    () => {
      const parsedAdvancedFilters = getTransformedAdvancedFilters(advancedFilters as TFilterItem[]);
      const advancedFiltersToSend = AdvancedFilters.mapAdvancedFiltersToCustomLabelIds(
        parsedAdvancedFilters,
        jobInfoCustomLabels
      );

      return axios
        .post<{
          resource: EventExplorerBatchResponse;
        }>(
          config.api.eventExplorerBatch,
          new EventExplorerBatchRequest(
            jobId,
            tablePageSize,
            (tablePage - 1) * tablePageSize,
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
            startDate ? startDate.toISOString() : null,
            endDate ? endDate.toISOString() : null,
            tableSort,
            advancedFiltersToSend
          )
        )
        .then((responseData: AxiosResponse<{ resource: EventExplorerBatchResponse }>) => {
          return jsonConvert.deserializeObject(responseData.data.resource, EventExplorerBatchResponse);
        });
    },
    queryOptions
  );
}
