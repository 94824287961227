import { ApiKeysListResponse } from '@arcanna/models/ApiKeys';
import { RequestCommon, ResourceWrapper } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { apiKeysKeys } from 'src/_srcMUI/requests/ApiKeys/keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

function useApiKeysList() {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  // SETUP
  const URL = useMemo(() => config.api.apiKeys.list, []);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<ApiKeysListResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, ApiKeysListResponse)),
    [URL]
  );

  // PAYLOAD
  const payload = useMemo(() => new RequestCommon(), []);
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(apiKeysKeys.list(), () => axiosFunction(payloadSerialized), {
    onError: () => {
      showErrorNotification(t('requests:apiKeys.getApiKeys.error.title'), t('requests:apiKeys.getApiKeys.error.subtitle'));
    },
    staleTime: Infinity
  });
}

export default useApiKeysList;
