import { EIcon, Icon, Spinner } from '@arcanna/generic';
import { Button, Popover, Stack, SxProps, Theme, useTheme } from '@mui/material';
import * as React from 'react';

type TPopoverButton = {
  text: string;
  children: (handleClose: () => void) => React.ReactNode;
  icon?: EIcon;
  menuSx?: SxProps<Theme>;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export default function PopoverButton({ text, children, icon, menuSx, isDisabled = false, isLoading = false }: TPopoverButton) {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = isOpen ? 'simple-popover' : undefined;

  const endIcon = icon ?? (isOpen ? EIcon.ChevronDown : EIcon.ChevronUp);

  return (
    <div>
      <Button
        disableRipple
        variant={isOpen ? 'containedHover' : 'contained'}
        color="secondary"
        size="small"
        onClick={handleClick}
        endIcon={isLoading ? <Spinner /> : <Icon name={endIcon} />}
        disabled={isDisabled || isLoading}
      >
        {text}
      </Button>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        sx={{ marginTop: '4px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack
          gap={'16px'}
          flexDirection={'column'}
          border={`1px solid ${palette.info.main}`}
          borderRadius={'4px'}
          padding={'12px'}
          sx={menuSx}
        >
          {children(handleClose)}
        </Stack>
      </Popover>
    </div>
  );
}
