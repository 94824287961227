import { TFeedbackTableRowData } from '@arcanna/pages/Feedback/components/FeedbackTable/FeedbackTable.types';
import { useMemo } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { CustomLabel } from '@arcanna/components';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';
import { noDecisionResult, undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';
import { Cell } from '@tanstack/react-table';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { useFeedbackEngineStore } from 'src/_srcMUI/shared/components/FeedbackEngine/store/FeedbackEngineStore';
import { TRCAExplorerTableRowData } from '@arcanna/pages/Explorer/components/RCAExplorer/components/RCAExplorerTable/RCAExplorerTable.types';
import { TECDIExplorerTableRowData } from '@arcanna/pages/Explorer/components/ECDIExplorer/components/ECDIExplorerTab/components/ECDIExplorerTable/ECDIExplorerTable.types';
import { getLabelClearSelected, getLabelConfirmSelected, getMatchingLabel } from './LabelColumn.utils';

type TLabelColumnProps = {
  cell: Cell<TECDIExplorerTableRowData, string> | Cell<TRCAExplorerTableRowData, string> | Cell<TFeedbackTableRowData, string>;
  isDisplayedOnTwoRows: boolean;
  jobInfoCustomLabels: CustomLabelByJob[];
  consensus: string | null;
};

function LabelColumn({ cell, isDisplayedOnTwoRows, jobInfoCustomLabels, consensus }: TLabelColumnProps) {
  const selectedCustomLabel = useFeedbackEngineStore((store) => store.selectedCustomLabel);
  const isConfirmSelected = useFeedbackEngineStore((store) => store.isConfirmSelected);
  const isClearSelected = useFeedbackEngineStore((store) => store.isClearSelected);
  const isOverwriteMode = useFeedbackEngineStore((store) => store.isOverwriteMode);

  const row = cell.row;
  const rowSelectedLabel = row.getIsSelected() ? selectedCustomLabel : null;
  const rowData = row.original;
  const labelId = rowData.label;
  const jobId = Number(rowData.job_id);
  const currentJobInfoLabels = jobInfoCustomLabels.filter((customLabel) => {
    return customLabel.jobIds != undefined ? customLabel.jobIds.includes(jobId) : true;
  });

  // @ts-ignore
  const hasConsensusOverwritten = useMemo(() => {
    if (row.original.consensusOverwritten === null || row.original.consensusOverwritten === undefined) {
      return false;
    }

    // ECDI Case
    if (typeof row.original?.consensusOverwritten === 'boolean') {
      return row.original.consensusOverwritten;
    }

    // DI Case
    // @ts-ignore
    return Boolean(row.original?.consensusOverwritten?.consensusOverwritten);
  }, [row.original.consensusOverwritten]);

  const { newLabel, isNewLabelOverwritten } = useMemo(() => {
    if (isConfirmSelected && row.getIsSelected()) {
      return getLabelConfirmSelected({
        currentJobInfoLabels,
        arcannaLabelId: labelId,
        currentLabelId: consensus,
        isOverwriteMode,
        hasConsensusOverwritten
      });
    }

    if (isClearSelected && row.getIsSelected()) {
      return getLabelClearSelected({
        currentJobInfoLabels,
        currentLabelId: consensus,
        isOverwriteMode,
        hasConsensusOverwritten
      });
    }

    const matchingLabel = getMatchingLabel({
      currentJobInfoLabels,
      currentLabelId: consensus,
      selectedLabel: rowSelectedLabel,
      isOverwriteMode,
      hasConsensusOverwritten
    });

    return matchingLabel;
  }, [
    isConfirmSelected,
    row,
    isClearSelected,
    rowSelectedLabel,
    currentJobInfoLabels,
    hasConsensusOverwritten,
    consensus,
    isOverwriteMode,
    labelId
  ]);

  let oldValue: CustomLabelType | undefined =
    labelId === undecidedConsensus.id
      ? undecidedConsensus
      : currentJobInfoLabels.find((customLabel) => customLabel.id === labelId);

  if (oldValue == null) {
    oldValue = noDecisionResult;
  }

  const hasNewValue = newLabel && newLabel !== oldValue && newLabel !== undefined;

  const isFeedbackSelected = useMemo(
    () => (Boolean(newLabel) && newLabel !== oldValue) || (isConfirmSelected && selectedCustomLabel?.id !== undefined),
    [newLabel, oldValue, isConfirmSelected, selectedCustomLabel]
  );

  const isTouched = useMemo(
    () => selectedCustomLabel || isClearSelected || isConfirmSelected,
    [selectedCustomLabel, isClearSelected, isConfirmSelected]
  );

  const getContent = (width?: number) =>
    oldValue ? (
      <Stack
        direction={isDisplayedOnTwoRows ? 'column' : 'row'}
        alignItems={isDisplayedOnTwoRows ? 'flex-start' : 'center'}
        gap={1}
      >
        <CustomLabel
          name={oldValue.name}
          dataTestId="label-column-value"
          hexColor={oldValue.hexColor}
          disabled={isFeedbackSelected}
          strike={isFeedbackSelected}
          fontSize="12px"
          lineHeight="20px"
          disableColorCircle={oldValue === noDecisionResult || oldValue.name === undecidedConsensus.name}
          isConfirmed={newLabel === oldValue}
          hasConsensusOverwritten={
            isTouched ? isNewLabelOverwritten && newLabel === oldValue : hasConsensusOverwritten && newLabel === oldValue
          }
          width={width && (hasNewValue && !isDisplayedOnTwoRows ? width / 2 : width)}
        />
        {hasNewValue && (
          <CustomLabel
            name={newLabel.name}
            dataTestId="new-label-column-value"
            disableColorCircle={newLabel === undecidedConsensus}
            hexColor={newLabel.hexColor}
            fontSize="12px"
            lineHeight="20px"
            hasConsensusOverwritten={isNewLabelOverwritten || (hasConsensusOverwritten && !isTouched)}
            width={width && !isDisplayedOnTwoRows ? width / 2 : undefined}
          />
        )}
      </Stack>
    ) : (
      <></>
    );

  return oldValue ? (
    <Tooltip
      title={getContent()}
      enterDelay={1000}
      enterNextDelay={1000}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 'unset',
            width: 'auto'
          }
        }
      }}
    >
      {getContent(cell.column.getSize() - 32)}
    </Tooltip>
  ) : null;
}

export default LabelColumn;
