import { AlertDialog } from '@arcanna/components';
import { Stack, Tooltip } from '@mui/material';
import { JobCategory } from 'src/components/pages/Main/Jobs/helper';
import { TFeedbackUpdate } from '../FeedbackEngineBulkBar/FeedbackEngineBulkBar';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFeedbackEngineStore } from '../store/FeedbackEngineStore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFeedback from '@arcanna/requests/Event/useFeedback';
import {
  constructFeedbackEventJobUpdate,
  constructFeedbackEventJobUpdateEntry,
  constructFeedbackEventRequest,
  FeedbackEventJobUpdate,
  FeedbackEventJobUpdateEntry
} from '@arcanna/models/Event/FeedbackEventRequest';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';
import { useJobInfo, useUpdateEventCluster } from 'src/data-access';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { EventClusterUpdate, EventUpdateClusterRequest } from 'src/components/shared/models/events/EventUpdateClusterRequest';
import { Spinner } from '@arcanna/generic';

type TSaveCancelAreaProps = {
  eventsOrBuckets: TFeedbackUpdate[];
  handleClearSelection: () => void;
};

function SaveCancelArea({ eventsOrBuckets, handleClearSelection }: TSaveCancelAreaProps) {
  const { t } = useTranslation(['common', 'feedback']);
  const { jobId } = useJobIdFromUrl();

  const { data: jobInfoData } = useJobInfo(jobId);
  const jobCategory = useMemo(() => jobInfoData?.info?.jobDetails?.category_id, [jobInfoData]);
  const isECDIJob = useMemo(() => jobCategory === JobCategory.EVENT_CENTRIC_DECISION_INTELLIGENCE, [jobCategory]);

  const reset = useFeedbackEngineStore((store) => store.reset);
  const isClearSelected = useFeedbackEngineStore((store) => store.isClearSelected);
  const isConfirmSelected = useFeedbackEngineStore((store) => store.isConfirmSelected);
  const selectedCustomLabel = useFeedbackEngineStore((store) => store.selectedCustomLabel);
  const selectedClusterId = useFeedbackEngineStore((store) => store.selectedClusterId);
  const isOverwriteMode = useFeedbackEngineStore((store) => store.isOverwriteMode);
  const setIsUpdating = useFeedbackEngineStore((store) => store.setIsUpdating);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const {
    addFeedbackMutation: { mutateAsync: updateFeedback, isLoading: isUpdatingFeedback },
    removeFeedbackMutation: { mutateAsync: removeFeedback, isLoading: isRemovingFeedback }
  } = useFeedback();
  const { mutateAsync: updateCluster, isLoading: isUpdatingCluster } = useUpdateEventCluster({ jobId });

  const handleClusterIdSave = useCallback(
    async (eventsOrBucketsToSave: TFeedbackUpdate[], selectedClusterIdToSave: string) => {
      // @ts-ignore
      const clusterUpdates: EventClusterUpdate[] = eventsOrBucketsToSave.map((eventOrBucket) => ({
        entry_id: eventOrBucket.eventId,
        cluster_id_destination: selectedClusterIdToSave
      }));

      await updateCluster(new EventUpdateClusterRequest(jobId, clusterUpdates));
    },
    [updateCluster, jobId]
  );

  const handleClearFeedback = useCallback(
    async (eventsOrBucketsToClear: TFeedbackUpdate[]) => {
      const updatesGroupedPerJob: Record<number, FeedbackEventJobUpdateEntry[]> = eventsOrBucketsToClear.reduce(
        (acc, eventOrBucket) => ({
          ...acc,
          [eventOrBucket.jobId]: [
            // @ts-ignore
            ...(acc[eventOrBucket.jobId] ?? []),
            constructFeedbackEventJobUpdateEntry({
              eventId: eventOrBucket.eventId,
              bucketId: eventOrBucket.bucketId
            })
          ]
        }),
        {}
      );

      const updatesPerJob: FeedbackEventJobUpdate[] = Object.keys(updatesGroupedPerJob).map((jobId) =>
        constructFeedbackEventJobUpdate({ jobId: Number(jobId), updates: updatesGroupedPerJob[Number(jobId)] })
      );

      const payload = constructFeedbackEventRequest({ isOverwriteMode, updatesPerJob });

      await removeFeedback(payload);
    },
    [isOverwriteMode, removeFeedback]
  );

  const handleFeedbackSave = useCallback(
    async (eventsOrBucketsToSave: TFeedbackUpdate[], selectedCustomLabelToSave: CustomLabelByJob | null) => {
      if (isClearSelected) {
        await handleClearFeedback(eventsOrBucketsToSave);
        return;
      }

      if (!selectedCustomLabelToSave && !isConfirmSelected) {
        return;
      }

      // Filtering so that we do not send class_0 which is on any job by mistake on jobs we do not want
      const filteredEventsOrBucketsToSave = eventsOrBucketsToSave.filter((eventOrBucket) =>
        selectedCustomLabelToSave && !isConfirmSelected && selectedCustomLabelToSave?.jobIds
          ? selectedCustomLabelToSave?.jobIds?.includes(eventOrBucket.jobId)
          : true
      );

      const eventsOrBucketsFiltered = isConfirmSelected
        ? filteredEventsOrBucketsToSave.filter(
            (eventOrBucket) => eventOrBucket.arcannaLabelId && eventOrBucket.arcannaLabelId !== ARCANNA_NO_DECISION
          )
        : filteredEventsOrBucketsToSave;

      if (!eventsOrBucketsFiltered.length) {
        return;
      }

      const updatesGroupedPerJob: Record<number, FeedbackEventJobUpdateEntry[]> = eventsOrBucketsFiltered.reduce(
        (acc, eventOrBucket) => ({
          ...acc,
          [eventOrBucket.jobId]: [
            // @ts-ignore
            ...(acc[eventOrBucket.jobId] ?? []),
            constructFeedbackEventJobUpdateEntry({
              eventId: eventOrBucket.eventId,
              bucketId: eventOrBucket.bucketId,
              // @ts-ignore
              newLabel: (isConfirmSelected ? eventOrBucket.arcannaLabelId : selectedCustomLabelToSave.id) as string
            })
          ]
        }),
        {}
      );

      const updatesPerJob: FeedbackEventJobUpdate[] = Object.keys(updatesGroupedPerJob).map((jobId) =>
        constructFeedbackEventJobUpdate({ jobId: Number(jobId), updates: updatesGroupedPerJob[Number(jobId)] })
      );

      const payload = constructFeedbackEventRequest({ isOverwriteMode, updatesPerJob });

      await updateFeedback(payload);
    },
    [handleClearFeedback, isClearSelected, isConfirmSelected, isOverwriteMode, updateFeedback]
  );

  const handleSave = useCallback(
    async (
      eventsOrBuckets: TFeedbackUpdate[],
      selectedCustomLabelToSave: CustomLabelByJob | null,
      selectedClusterIdToSave: string | null
    ) => {
      if (isOverwriteMode && !isConfirmDialogOpen) {
        setIsConfirmDialogOpen(true);
        return;
      }

      setIsUpdating(true);
      if (selectedClusterIdToSave) {
        await handleClusterIdSave(eventsOrBuckets, selectedClusterIdToSave);
      }

      await handleFeedbackSave(eventsOrBuckets, selectedCustomLabelToSave);
      setIsUpdating(false);

      handleClearSelection();
    },
    [handleClearSelection, handleClusterIdSave, handleFeedbackSave, isConfirmDialogOpen, isOverwriteMode, setIsUpdating]
  );

  const handleCancel = useCallback(() => {
    handleClearSelection();
    reset();
  }, [handleClearSelection, reset]);

  const isSaveCancelDisabled = useMemo(
    () => eventsOrBuckets.length === 0 || (!selectedCustomLabel && !isConfirmSelected && !isClearSelected && !selectedClusterId),
    [eventsOrBuckets, selectedCustomLabel, selectedClusterId, isConfirmSelected, isClearSelected]
  );

  // eslint-disable-next-line
  const handleKeyDown = (event: KeyboardEvent) => {
    const confirmKey = (event.ctrlKey && event.key === 's') || event.key === 'Enter';
    const cancelKey = event.ctrlKey && event.key === 'x';

    if (!isSaveCancelDisabled && confirmKey) {
      handleSave(eventsOrBuckets, selectedCustomLabel, selectedClusterId);
      event.preventDefault();
    } else if (!isSaveCancelDisabled && cancelKey) {
      handleCancel();
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, [handleKeyDown]);

  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <PermissionsCheck testId="save">
        <Tooltip title={t('feedback:ctrlS')} placement="top">
          <Button
            disabled={isSaveCancelDisabled || isUpdatingFeedback || isRemovingFeedback || isUpdatingCluster}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleSave(eventsOrBuckets, selectedCustomLabel, selectedClusterId)}
            endIcon={isUpdatingFeedback || isRemovingFeedback || isUpdatingCluster ? <Spinner /> : <></>}
            data-test-id="save-feedback-button"
          >
            <span>{t('common:savePlaceholder', { placeholder: eventsOrBuckets.length })}</span>
          </Button>
        </Tooltip>
      </PermissionsCheck>

      <PermissionsCheck testId={'cancel'}>
        <Tooltip title={t('feedback:ctrlX')} placement="top">
          <Button variant="outlined" color="secondary" size="small" onClick={handleCancel} data-test-id="cancel-feedback-button">
            {t('common:cancel')}
          </Button>
        </Tooltip>
      </PermissionsCheck>

      <AlertDialog
        open={isConfirmDialogOpen}
        handleSubmit={() => handleSave(eventsOrBuckets, selectedCustomLabel, selectedClusterId)}
        isLoading={isUpdatingFeedback || isRemovingFeedback || isUpdatingCluster}
        onClose={() => setIsConfirmDialogOpen(false)}
        title={isClearSelected ? 'Clear Overwritten Consensus?' : 'Overwrite Consensus'}
        modalBody={
          isClearSelected
            ? // eslint-disable-next-line max-len
              `You are about to clear the consensus for ${eventsOrBuckets.length} ${isECDIJob ? 'events' : 'buckets'}. New decisions will be taken into consideration.`
            : // eslint-disable-next-line max-len
              `You are about to overwrite the consensus for ${eventsOrBuckets.length} ${isECDIJob ? 'events' : 'buckets'}. New decisions won't be taken into consideration.`
        }
        mode="confirm"
      />
    </Stack>
  );
}

export default SaveCancelArea;
