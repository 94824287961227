import * as React from 'react';
import { Menu } from 'antd';
import { useActiveNavigationItem } from './useActiveNavigationItem.hook';
import { withNavigationItem } from './NavigationItem.hoc';
import { SettingsIcon } from '../../../../shared/icons/SettingsIcon';
import { ExplorerIcon } from '../../../../shared/icons/ExplorerIcon';
import { useApp } from '../../../../App/App.context';
import { permissions } from '../../../../shared/static/ComponentsPermissions';
import { TrainIcon } from '../../../../shared/icons/TrainIcon';
import { config } from '../../../../../config';
import { JOB_NAVIGATION_KEYS } from './JobNavigation';
import style from './JobNavigation.module.css';
import { ClustersIcon } from '../../../../shared/icons/ClustersIcon';
import JobSettingsNavItem from './JobSettingsNavItem';
import JobExplorerNavItem from './JobExplorerNavItem';
import JobTrainNavItem from './JobTrainNavItem';
import JobClustersNavItem from './JobClustersNavItem';
import RCAJobOverviewNavItem from './RCAJobOverviewNavItem';
import { OverviewIcon } from '../../../../shared/icons/OverviewIcon';
import { FlowsIcon } from 'src/themes/icons/FlowsIcon';
import JobFlowsNavItem from './JobFlowsNavItem';

const iconItemStyle = {
  overflow: 'visible',
  height: 'auto',
  lineHeight: 'normal',
  marginTop: 24,
  padding: 0
};

type RCANavItemsProps = {
  jobId: string;
  isExpanded: boolean;
};

export default function RCANavItems({ jobId, isExpanded }: RCANavItemsProps) {
  const activeKey = useActiveNavigationItem();
  const [iconItems, setIconItems] = React.useState([]);
  const { hasAccessFor } = useApp();
  const hasJobRetrainAccess = hasAccessFor([permissions.retrainRead]);
  const hasJobSettingAccess = hasAccessFor([permissions.jobUpdate]);
  const hasJobUpdatePermission = hasAccessFor([permissions.jobUpdate]);

  const RCAOverviewItem = withNavigationItem(OverviewIcon);
  const ClustersItem = withNavigationItem(ClustersIcon);
  const ExplorerItem = withNavigationItem(ExplorerIcon);
  const TrainItem = withNavigationItem(TrainIcon);
  const SettingsItem = withNavigationItem(SettingsIcon);
  const FlowsItem = withNavigationItem(FlowsIcon);

  React.useEffect(
    function computeNavigationIcons() {
      const items = [
        {
          icon: (
            <RCAOverviewItem
              to={config.routes.jobOverView.replace(':id', jobId)}
              active={activeKey === JOB_NAVIGATION_KEYS.JOB_OVERVIEW}
              activeColor={'#F0F6FC'}
              dataTestId={'menu-item-overview'}
            />
          ),
          key: JOB_NAVIGATION_KEYS.JOB_OVERVIEW,
          style: iconItemStyle
        },
        {
          icon: (
            <ClustersItem
              to={config.routes.jobClusters.replace(':id', jobId)}
              active={activeKey === JOB_NAVIGATION_KEYS.JOB_CLUSTERS}
              activeColor={'#F0F6FC'}
              dataTestId={'menu-item-clusters'}
            />
          ),
          key: JOB_NAVIGATION_KEYS.JOB_CLUSTERS,
          style: iconItemStyle
        }
      ];

      items.push({
        icon: (
          <ExplorerItem
            to={config.routes.eventExplorer.replace(':id', jobId)}
            active={activeKey === JOB_NAVIGATION_KEYS.JOB_EXPLORER}
            activeColor={'#F0F6FC'}
            dataTestId={'menu-item-explorer'}
          />
        ),
        key: JOB_NAVIGATION_KEYS.JOB_EXPLORER,
        style: iconItemStyle
      });

      if (hasJobUpdatePermission) {
        items.push({
          icon: (
            <FlowsItem
              to={config.routes.flows.replace(':id', jobId)}
              active={activeKey === JOB_NAVIGATION_KEYS.JOB_FLOWS}
              activeColor={'#F0F6FC'}
              dataTestId={'menu-item-flows'}
            />
          ),
          key: JOB_NAVIGATION_KEYS.JOB_FLOWS,
          style: iconItemStyle
        });
      }

      if (hasJobRetrainAccess) {
        items.push({
          icon: (
            <TrainItem
              to={config.routes.retrainJob.replace(':id', jobId)}
              active={activeKey === JOB_NAVIGATION_KEYS.JOB_TRAIN}
              activeColor={'#F0F6FC'}
              dataTestId={'menu-item-train'}
            />
          ),
          key: JOB_NAVIGATION_KEYS.JOB_TRAIN,
          style: iconItemStyle
        });
      }

      if (hasJobSettingAccess) {
        items.push({
          icon: (
            <SettingsItem
              to={config.routes.editJob.replace(':id', jobId)}
              active={activeKey === JOB_NAVIGATION_KEYS.JOB_SETTINGS}
              activeColor={'#F0F6FC'}
              dataTestId={'menu-item-settings'}
            />
          ),
          key: JOB_NAVIGATION_KEYS.JOB_SETTINGS,
          style: { ...iconItemStyle, marginTop: 65 }
        });
      }
      // @ts-expect-error TS(2345): Argument of type '{ icon: Element; key: JOB_NAVIGA...
      setIconItems(items);
    },
    // eslint-disable-next-line
    [setIconItems]
  );

  return (
    <>
      <Menu
        style={{ borderRight: '0px', overflow: 'display' }}
        theme="dark"
        // @ts-expect-error TS(2769): No overload matches this call.
        selectedKeys={[activeKey]}
        defaultSelectedKeys={['job-overview-rca']}
        mode="vertical"
        items={iconItems}
      />
      {isExpanded ? (
        <div className={style.expandSider}>
          <RCAJobOverviewNavItem jobId={jobId} />

          <JobClustersNavItem jobId={jobId} />

          <JobExplorerNavItem jobId={jobId} />

          <JobFlowsNavItem jobId={jobId} />

          <JobTrainNavItem jobId={jobId} />

          <JobSettingsNavItem jobId={jobId} />
        </div>
      ) : null}
    </>
  );
}
