import { matchPath, useLocation } from 'react-router-dom';
import { config } from '../../../../../config';
import { JOB_NAVIGATION_KEYS } from './JobNavigation';

export function useActiveNavigationItem() {
  const location = useLocation();

  if (matchPath(location.pathname, { path: config.routes.jobOverView })) {
    return JOB_NAVIGATION_KEYS.JOB_OVERVIEW;
  }
  if (matchPath(location.pathname, { path: config.routes.feedbackFlow })) {
    return JOB_NAVIGATION_KEYS.JOB_FEEDBACK;
  }
  if (matchPath(location.pathname, { path: config.routes.featureSelectionJob })) {
    return JOB_NAVIGATION_KEYS.JOB_FEEDBACK;
  }
  if (matchPath(location.pathname, { path: config.routes.retrainJob })) {
    return JOB_NAVIGATION_KEYS.JOB_TRAIN;
  }
  if (matchPath(location.pathname, { path: config.routes.eventExplorer })) {
    return JOB_NAVIGATION_KEYS.JOB_EXPLORER;
  }
  if (matchPath(location.pathname, { path: config.routes.eventExplorerColumnsSelection })) {
    return JOB_NAVIGATION_KEYS.JOB_EXPLORER;
  }
  if (matchPath(location.pathname, { path: config.routes.editJob })) {
    return JOB_NAVIGATION_KEYS.JOB_SETTINGS;
  }
  if (matchPath(location.pathname, { path: config.routes.jobClusters })) {
    return JOB_NAVIGATION_KEYS.JOB_CLUSTERS;
  }
  if (matchPath(location.pathname, { path: config.routes.flows })) {
    return JOB_NAVIGATION_KEYS.JOB_FLOWS;
  }
}
