import { Select } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { useMemo } from 'react';
import useClustersIdsSuggestions from 'src/data-access/clusters/useClustersIdsSuggestions';
import { useFeedbackEngineStore } from '../store/FeedbackEngineStore';

type TSelectClusterAreaProps = {
  isDisabled: boolean;
};

function SelectClusterArea({ isDisabled }: TSelectClusterAreaProps) {
  const { jobId } = useJobIdFromUrl();
  const clusterIdsSuggestions = useClustersIdsSuggestions({
    jobId,
    searchValue: ''
  });
  const selectedClusterId = useFeedbackEngineStore((store) => store.selectedClusterId);
  const setSelectedClusterId = useFeedbackEngineStore((store) => store.setSelectedClusterId);

  const clusterOptions = useMemo(
    () =>
      clusterIdsSuggestions.data?.suggestions.map((clusterId: string) => ({
        label: clusterId,
        value: clusterId
      })) ?? [],
    [clusterIdsSuggestions.data]
  );

  return (
    <Select
      options={clusterOptions}
      isTextInputIncluded={false}
      onChange={(newValue) => setSelectedClusterId(newValue)}
      value={selectedClusterId ?? ''}
      placeholder="Move to another cluster"
      sx={{ minWidth: '250px' }}
      disabled={isDisabled}
    />
  );
}

export default SelectClusterArea;
