/* eslint-disable @typescript-eslint/no-empty-function */
import { CustomLabel } from '@arcanna/components';
import { Spinner } from '@arcanna/generic';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useIsFetching, useQueryClient } from 'react-query';
import OverwriteConsensusModeSwitch from 'src/_srcMUI/shared/components/FeedbackEngine/OverwriteConsensusModeSwitch';
import { useApp } from 'src/components/App/App.context';
import { noDecisionResult, undecidedConsensus } from 'src/components/pages/Main/Jobs/helper';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import StyledFeedbackEngineSingleBar from './StyledFeedbackEngineSingleBar';
import ConfirmArcannaDecisionButton from '../ConfirmArcannaDecisionButton';
import ClearMyFeedbackButton from '../ClearMyFeedbackButton';
import SelectCustomLabelArea from '../SelectCustomLabelArea';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { ReactNode, useCallback, useMemo } from 'react';
import { ARCANNA_NO_DECISION } from 'src/components/pages/JobEventExplorer/utils';
import ExpandedDrawerNavigation from '../../ExpandedDrawerNavigation';
import { TExpandedDrawerNavigationProps } from '../../ExpandedDrawerNavigation/ExpandedDrawerNavigation';
import {
  constructFeedbackEventJobUpdate,
  constructFeedbackEventJobUpdateEntry,
  constructFeedbackEventRequest
} from '@arcanna/models/Event/FeedbackEventRequest';
import useFeedback from '@arcanna/requests/Event/useFeedback';
import { TFeedbackUpdate } from '../FeedbackEngineBulkBar/FeedbackEngineBulkBar';
import { useFeedbackEngineStore } from '../store/FeedbackEngineStore';
import { useJobInfo } from 'src/data-access';

type TFeedbackEngineSingleBarProps = {
  jobId: number;
  customLabels: CustomLabelByJob[];
  eventOrBucket: TFeedbackUpdate;
  navigationProps: TExpandedDrawerNavigationProps;
  extraInfos?: {
    title: string;
    content: ReactNode;
  }[];
  invalidateQueryKeys?: string[];
  isLoading: boolean;
  isDisabled?: boolean;
};

function FeedbackEngineSingleBar({
  jobId,
  customLabels,
  eventOrBucket,
  navigationProps,
  extraInfos,
  invalidateQueryKeys,
  isLoading,
  isDisabled = false
}: TFeedbackEngineSingleBarProps) {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { data: jobInfoData } = useJobInfo(jobId);
  const jobCategory = useMemo(() => jobInfoData?.info?.jobDetails?.category_id, [jobInfoData]);

  const isUpdating = useFeedbackEngineStore((store) => store.isUpdating);
  const setIsUpdating = useFeedbackEngineStore((store) => store.setIsUpdating);
  const isOverwriteMode = useFeedbackEngineStore((store) => store.isOverwriteMode);

  const {
    addFeedbackMutation: { mutateAsync: updateFeedback },
    removeFeedbackMutation: { mutateAsync: removeFeedback }
  } = useFeedback();

  const areInvalidateQueriesFetching = useIsFetching(invalidateQueryKeys) > 0;

  const shouldDisplaySpinner = useMemo(
    () => isLoading || isUpdating || areInvalidateQueriesFetching,
    [isLoading, isUpdating, areInvalidateQueriesFetching]
  );

  const isConfirmDisabled = useMemo(
    () => !(eventOrBucket.arcannaLabelId && eventOrBucket.arcannaLabelId !== ARCANNA_NO_DECISION),
    [eventOrBucket.arcannaLabelId]
  );

  const isClearDisabled = useMemo(
    () => !(eventOrBucket.currentLabelId && eventOrBucket.currentLabelId !== ARCANNA_NO_DECISION),
    [eventOrBucket.currentLabelId]
  );

  const arcannaLabel = useMemo(
    () => customLabels.find((label) => label.id === eventOrBucket.arcannaLabelId) ?? noDecisionResult,
    [eventOrBucket.arcannaLabelId, customLabels]
  );

  const currentLabel = useMemo(
    () => customLabels.find((label) => label.id === eventOrBucket.currentLabelId) ?? undecidedConsensus,
    [eventOrBucket.currentLabelId, customLabels]
  );

  const hasNewValue = useMemo(
    () => Boolean(eventOrBucket.currentLabelId && eventOrBucket.arcannaLabelId !== eventOrBucket.currentLabelId),
    [eventOrBucket.arcannaLabelId, eventOrBucket.currentLabelId]
  );

  const selectedLabel = useMemo(
    () => customLabels.find((label) => label.id === eventOrBucket.currentLabelId) ?? null,
    [eventOrBucket.currentLabelId, customLabels]
  );

  const onSuccess = useCallback(async () => {
    await queryClient.invalidateQueries(invalidateQueryKeys);
  }, [invalidateQueryKeys, queryClient]);

  const handleFeedbackSave = useCallback(
    async (labelId: string) => {
      setIsUpdating(true);
      const updates = [
        constructFeedbackEventJobUpdateEntry({
          eventId: eventOrBucket.eventId,
          bucketId: eventOrBucket.bucketId,
          newLabel: labelId
        })
      ];

      const updatesPerJob = [constructFeedbackEventJobUpdate({ jobId, updates })];

      const payload = constructFeedbackEventRequest({ isOverwriteMode, updatesPerJob });

      await updateFeedback(payload, {
        onSuccess: onSuccess
      });
      setIsUpdating(false);
    },
    [eventOrBucket.bucketId, eventOrBucket.eventId, isOverwriteMode, jobId, onSuccess, setIsUpdating, updateFeedback]
  );

  const handleFeedbackClear = useCallback(async () => {
    setIsUpdating(true);
    const updates = [
      constructFeedbackEventJobUpdateEntry({
        eventId: eventOrBucket.eventId,
        bucketId: eventOrBucket.bucketId
      })
    ];

    const updatesPerJob = [constructFeedbackEventJobUpdate({ jobId, updates })];

    const payload = constructFeedbackEventRequest({ isOverwriteMode, updatesPerJob });

    await removeFeedback(payload, {
      onSuccess: onSuccess
    });
    setIsUpdating(false);
  }, [eventOrBucket.bucketId, eventOrBucket.eventId, isOverwriteMode, jobId, onSuccess, removeFeedback, setIsUpdating]);

  const handleConfirm = useCallback(() => {
    eventOrBucket.arcannaLabelId && handleFeedbackSave(eventOrBucket.arcannaLabelId);
  }, [eventOrBucket.arcannaLabelId, handleFeedbackSave]);

  const handleLabelSelection = useCallback(
    (selectedLabel: CustomLabelByJob | null) => {
      selectedLabel?.id && handleFeedbackSave(selectedLabel.id);
    },
    [handleFeedbackSave]
  );

  const { hasAccessFor } = useApp();
  const hasConsensusOverwritePermission = useMemo(() => hasAccessFor([permissions.feedbackOverwriteUpdate]), [hasAccessFor]);

  const consensusOverwrittenTooltipMessage = useMemo(
    () =>
      hasConsensusOverwritePermission
        ? 'Enable overwrite consensus mode to change the decision.'
        : "You can't change the feedback because the consensus has been overwritten.",
    [hasConsensusOverwritePermission]
  );

  return (
    <StyledFeedbackEngineSingleBar>
      {shouldDisplaySpinner && <Spinner isOverlay />}
      <Stack direction="row" sx={{ borderBottom: '1px solid', borderColor: theme.palette.secondary[700] }}>
        <Stack
          p={2}
          gap={1}
          flexGrow={1}
          direction="column"
          alignItems="flex-start"
          sx={{ borderRight: '1px solid', borderColor: theme.palette.secondary[700] }}
        >
          <Typography variant="caption" color={theme.palette.secondary[300]}>
            Arcanna&apos;s Decision
          </Typography>
          <CustomLabel
            name={arcannaLabel.name}
            hexColor={arcannaLabel.hexColor}
            disabled={hasNewValue}
            strike={hasNewValue}
            fontSize="12px"
            lineHeight="20px"
            disableColorCircle={arcannaLabel === noDecisionResult || arcannaLabel.name === undecidedConsensus.name}
            isConfirmed={arcannaLabel === currentLabel}
            hasConsensusOverwritten={eventOrBucket.hasConsensusOverwritten && arcannaLabel === currentLabel}
            width={160}
          />
          {hasNewValue && (
            <CustomLabel
              name={currentLabel.name}
              disableColorCircle={currentLabel === undecidedConsensus}
              hexColor={currentLabel.hexColor}
              fontSize="12px"
              lineHeight="20px"
              hasConsensusOverwritten={eventOrBucket.hasConsensusOverwritten && hasNewValue}
              width={160}
            />
          )}
        </Stack>
        {extraInfos?.map((extraInfo, index) => (
          <Stack
            key={index}
            p={2}
            gap={1}
            flexGrow={1}
            sx={{ borderRight: '1px solid', borderColor: theme.palette.secondary[700] }}
          >
            <Typography variant="caption" color={theme.palette.secondary[300]}>
              {extraInfo.title}
            </Typography>
            <Typography variant="body2">{extraInfo.content}</Typography>
          </Stack>
        ))}
        {navigationProps.currentIndex && (
          <Stack p={2} gap={1}>
            <Typography variant="caption" color={theme.palette.secondary[300]}>
              {eventOrBucket.bucketId ? 'Bucket' : 'Event'}
            </Typography>
            <ExpandedDrawerNavigation {...navigationProps} />
          </Stack>
        )}
      </Stack>

      <Stack p={1} pt={2} gap={3}>
        <OverwriteConsensusModeSwitch jobCategory={jobCategory} />
        <Tooltip
          title={eventOrBucket.hasConsensusOverwritten && !isOverwriteMode ? consensusOverwrittenTooltipMessage : ''}
          arrow
        >
          <Stack direction="row" gap={2} alignItems="flex-start">
            <Stack direction="column" gap={2}>
              <ConfirmArcannaDecisionButton
                isDisabled={
                  isConfirmDisabled || Boolean((eventOrBucket.hasConsensusOverwritten && !isOverwriteMode) || isDisabled)
                }
                handleConfirm={handleConfirm}
              />
              <ClearMyFeedbackButton
                isDisabled={isClearDisabled || Boolean((eventOrBucket.hasConsensusOverwritten && !isOverwriteMode) || isDisabled)}
                handleClear={handleFeedbackClear}
              />
            </Stack>
            <SelectCustomLabelArea
              jobId={jobId}
              jobCategory={jobCategory}
              customLabels={customLabels}
              selectedLabel={selectedLabel}
              onSelect={handleLabelSelection}
              isDisabled={(eventOrBucket.hasConsensusOverwritten && !isOverwriteMode) || isDisabled}
              limitShownLabels={3}
            />
          </Stack>
        </Tooltip>
      </Stack>
    </StyledFeedbackEngineSingleBar>
  );
}

export default FeedbackEngineSingleBar;
