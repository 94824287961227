import { ConfirmPopover, EIcon, Icon, Spinner } from '@arcanna/generic';
import { Button } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import useRollbackInProgress from 'src/components/pages/Main/Jobs/OverviewRevamp/JobSummary/useRollbackInProgress';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { useJobNavigate } from 'src/components/shared/hooks/useJobNavigate';
import { EJobTrainingState } from 'src/components/shared/models/job/EJobTrainingState';
import { JobIsRetrainAvailableResponse } from 'src/components/shared/models/job/JobIsRetrainAvailableResponse';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import { config } from 'src/config';
import { useHasFeaturesSelected, useJobInfo, useRetrainJob } from 'src/data-access';
import useIsRetrainAvailable from 'src/data-access/job/useIsRetrainAvailable';

type TrainAIModelButtonProps = {
  jobId: number;
  onDecisionPointSelectionConfirm?: () => void;
};

function TrainAIModelButton({ jobId, onDecisionPointSelectionConfirm }: TrainAIModelButtonProps) {
  const { t } = useTranslation(['retrain']);
  const jobRetrainMutation = useRetrainJob();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const jobInfoQuery = useJobInfo(jobId);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isTrainError, setIsTrainError] = useState<boolean>(false);
  const [buttonPressed, setButtonPressed] = useState<number>(0);
  const [bucketUpdateTriggered, setBucketsUpdateTriggered] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const hasFeaturesSelectedQuery = useHasFeaturesSelected(jobId);
  const { isRollbackInProgress } = useRollbackInProgress({ jobId });

  const { navigateToJobFeatureSelection } = useJobNavigate();

  const isRetrainAvailableQuery = useIsRetrainAvailable(jobId);

  const retrainEnabled =
    (isRetrainAvailableQuery.data ? isRetrainAvailableQuery.data.enabled : false) &&
    jobRetrainMutation.status !== 'loading' &&
    jobInfoQuery?.data?.info?.status?.retrain_state !== EJobTrainingState.AUTOMATIC_RETRAIN &&
    !isRollbackInProgress;

  const handleOk = () => {
    setAnchorEl(null);

    if (onDecisionPointSelectionConfirm) {
      onDecisionPointSelectionConfirm();
    } else {
      navigateToJobFeatureSelection(`${jobId}`, t('job:navigation.train'));
    }
  };

  const handleCancel = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (jobInfoQuery.data !== null) {
      setInProgress(jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.RETRAIN_IN_PROGRESS);
      setIsTrainError(jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.ERROR);
    }
    if (jobInfoQuery.data?.info?.status?.buckets_model_update_in_progress && !bucketUpdateTriggered) {
      setBucketsUpdateTriggered(true);
      setButtonPressed((prevButtonPressed) => prevButtonPressed + 1);
    }
  }, [bucketUpdateTriggered, jobInfoQuery.data, buttonPressed, jobId, queryClient]);

  const handleRetrainJob = useCallback(() => {
    setInProgress(true);
    setButtonPressed((prevButtonPressed) => prevButtonPressed + 1);
    // @ts-expect-error
    queryClient.setQueryData([config.api.isJobRetrainAvailable, { jobId: jobId }], (oldData: JobIsRetrainAvailableResponse) => ({
      request: oldData.request,
      enabled: false
    }));
    jobRetrainMutation.mutate(jobId);
    // eslint-disable-next-line
  }, [jobId, jobRetrainMutation]);

  const onRetrainClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (hasFeaturesSelectedQuery.data) {
        handleRetrainJob();

        return;
      }

      // @ts-ignore
      setAnchorEl(event.target);
    },
    [handleRetrainJob, hasFeaturesSelectedQuery.data]
  );

  return (
    <>
      <PermissionsCheck permissions={[permissions.jobAction]} action={permissions.action.disabledAction}>
        <Button
          size="small"
          variant="contained"
          color="warning"
          onClick={onRetrainClick}
          data-test-id="retrain-button"
          disabled={
            !hasFeaturesSelectedQuery.data || (!isTrainError && (buttonPressed > 0 || bucketUpdateTriggered || !retrainEnabled))
          }
          endIcon={inProgress ? <Spinner /> : <Icon name={EIcon.Flash} />}
        >
          Train AI model
        </Button>
      </PermissionsCheck>
      <ConfirmPopover
        anchor={anchorEl}
        onConfirm={handleOk}
        onClose={handleCancel}
        text={t('job:retrain.featuresNotSelectedConfirm')}
      />
    </>
  );
}

export default TrainAIModelButton;
