/* eslint-disable max-len */
function Spark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_16220_275590)">
        <path
          d="M2.91431 19.1799V15.6492H6.45716"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0557 8.31296C19.4316 10.3117 19.131 12.3783 18.201 14.1885C17.2711 15.9986 15.7644 17.4498 13.9175 18.3144C12.0705 19.179 9.98781 19.4081 7.99611 18.9657C6.00442 18.5232 4.21648 17.4343 2.91284 15.8699"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.9443 11.6871C0.568398 9.68829 0.869005 7.62169 1.79895 5.81156C2.7289 4.00143 4.23556 2.5502 6.08251 1.68559C7.92947 0.820981 10.0122 0.591924 12.0039 1.03436C13.9956 1.4768 15.7835 2.56569 17.0872 4.13018"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0857 0.820129V4.35086H13.5428"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.84287 10.4755C5.34144 10.3887 5.34144 9.67114 5.84287 9.58429C6.7307 9.4303 7.55241 9.01631 8.20323 8.3951C8.85405 7.77389 9.3045 6.97361 9.49715 6.09628L9.52715 5.95818C9.63573 5.46416 10.3414 5.46131 10.4543 5.95391L10.4914 6.11478C10.6915 6.98814 11.146 7.78313 11.798 8.3999C12.4499 9.01667 13.2702 9.42772 14.1557 9.58145C14.66 9.66829 14.66 10.3901 14.1557 10.4784C13.2703 10.632 12.4502 11.0428 11.7983 11.6593C11.1464 12.2758 10.6917 13.0705 10.4914 13.9436L10.4543 14.1031C10.3414 14.5956 9.63573 14.5928 9.52715 14.0988L9.49858 13.9621C9.30574 13.0844 8.85486 12.2838 8.20349 11.6626C7.55212 11.0413 6.72979 10.6276 5.84144 10.4741L5.84287 10.4755Z"
          stroke="currentColor"
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16220_275590">
          <rect width="20" height="19.9315" fill="white" transform="translate(0 0.0342407)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Spark;
