import { FormGroup, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Checkbox, TCheckboxState } from '@arcanna/generic';
import lo from 'lodash';

export type CheckboxGroupProps = {
  onChange: (selectedOptions: string[] | string | undefined) => void;
  options: { label: string; value: string }[];
  defaultValues?: string[] | string;
  isMultipleChoice?: boolean;
  exclusiveOptions?: Record<string, string[]>;
};

export default function CheckboxGroup({
  onChange,
  options,
  defaultValues,
  isMultipleChoice = true,
  exclusiveOptions
}: CheckboxGroupProps) {
  const [selectedOptions, setSelectedOptions] = useState<Record<string, boolean>>({});
  const { palette } = useTheme();

  useEffect(() => {
    if (!defaultValues) {
      setSelectedOptions({});
      return;
    }
    if (!Array.isArray(defaultValues)) {
      setSelectedOptions({ [defaultValues]: true });
      return;
    }

    setSelectedOptions(
      defaultValues?.reduce((acc: Record<string, boolean>, val) => Object.assign(acc, { [val]: true }), {}) ?? {}
    );
  }, [defaultValues]);

  const handleChange = (_: TCheckboxState, event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedOptions = {
      ...selectedOptions
    };

    if (!isMultipleChoice) {
      setSelectedOptions(event.target.checked ? { [event.target.value]: event.target.checked } : {});
      onChange(event.target.checked ? event.target.value : undefined);
      return;
    }

    if (event.target.checked) {
      newSelectedOptions[event.target.value] = event.target.checked;

      exclusiveOptions?.[event.target.value]?.forEach((exclusion) => {
        if (lo.has(selectedOptions, exclusion)) {
          delete newSelectedOptions[exclusion];
        }
      });
    } else {
      if (lo.has(selectedOptions, event.target.value)) {
        delete newSelectedOptions[event.target.value];
      }
    }

    setSelectedOptions(newSelectedOptions);
    onChange(Object.keys(newSelectedOptions));
  };

  return (
    <FormGroup sx={{ gap: '8px' }}>
      {options?.map((option) => {
        return (
          <Checkbox
            textColor={palette.secondary[300]}
            activeColor={palette.secondary[100]}
            state={Object.keys(selectedOptions)?.includes(option.value) ? 'checked' : 'default'}
            label={option.label}
            value={option.value}
            onChange={handleChange}
            key={`group-item-${option.value}`}
          />
        );
      })}
    </FormGroup>
  );
}
