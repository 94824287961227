import { TableRow, TableRowProps, styled } from '@mui/material';
import { TABLE_SIZES } from '../TableSizeSelector/TableSizeSelector.constants';

const StyledRow = styled(TableRow, {
  shouldForwardProp(propName) {
    return (
      propName !== 'isHighlighted' && propName !== 'tableSize' && propName !== 'isStrippedTable' && propName !== 'isDisabled'
    );
  }
})<TableRowProps & { isHighlighted: boolean; tableSize: string; isStrippedTable: boolean; isDisabled: boolean }>(({
  theme,
  isHighlighted,
  tableSize,
  isStrippedTable,
  isDisabled
}) => {
  return {
    '&:not(:last-child)': { borderBottom: `1px solid ${theme.palette.secondary[700]}` },
    '.MuiTableCell-root': {
      background: isHighlighted ? theme.palette.secondary[800] : theme.palette.secondary[900],
      alignContent: tableSize === TABLE_SIZES.small ? 'center' : 'flex-start',
      padding: tableSize !== TABLE_SIZES.small && '8px 10px'
    },

    ...(isStrippedTable
      ? {
          '&:nth-of-type(2n+1)': {
            '.MuiTableCell-root': {
              background: isHighlighted ? theme.palette.secondary[800] : theme.palette.secondary[1000]
            },
            '.MuiTableCell-root--disabled': {
              background: theme.palette.secondary[700],
              opacity: 0.6
            }
          },
          '.MuiTableCell-root--disabled': {
            background: theme.palette.secondary[700],
            opacity: 0.6
          }
        }
      : {}),

    '&:hover': {
      background: theme.palette.secondary[800],

      '.MuiTableCell-root': {
        background: theme.palette.secondary[800]
      }
    },

    '.row-cell': {
      borderBottom: 0,
      padding: '0 8px',
      fontSize: '12px',
      lineHeight: '20px',
      position: 'sticky',
      left: 0,
      background: isHighlighted ? theme.palette.secondary[800] : theme.palette.secondary[900],
      zIndex: 1,
      boxShadow: `-4px 0 4px -4px ${theme.palette.secondary[600]} inset`,
      alignContent: tableSize === TABLE_SIZES.small ? 'center' : 'flex-start'
    },

    '.row-expand-button': {
      color: theme.palette.secondary[200],
      backgroundColor: theme.palette.secondary[1000],
      height: '16px',
      width: '16px',
      padding: 0,
      margin: 0
    },

    ...(isDisabled
      ? {
          opacity: 0.5
        }
      : {})
  };
});

export default StyledRow;
