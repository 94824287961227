import { TruncatedText } from '@arcanna/components';
import { useSortable } from '@dnd-kit/sortable';
import { flexRender, Header } from '@tanstack/react-table';
import { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { Button, Stack, Tooltip, useTheme } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';
import StyledTableHeaderCell from './StyledTableHeaderCell.styles';
import { useTranslation } from 'react-i18next';

type THeaderCellProps<T> = {
  headerCell: Header<T, unknown>;
  enableColumnSorting: boolean;
  actionsColumnSize: number;
};

function TableHeaderCell<T>({ headerCell, enableColumnSorting, actionsColumnSize }: THeaderCellProps<T>) {
  const { t } = useTranslation();
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: headerCell.id
  });
  const theme = useTheme();

  const isPinned = headerCell.column.getIsPinned();

  const hasDataSorting = headerCell.column.getCanSort();

  const headerValue = headerCell.column.columnDef.header;

  const renderedHeaderValue = headerCell.isPlaceholder
    ? null
    : flexRender(
        typeof headerValue === 'string' ? (
          <TruncatedText
            component="div"
            variant="subtitle2"
            ellipsisPosition="start"
            fontWeight={600}
            tooltipProps={{
              title: !isDragging && !headerCell.column.columnDef.meta?.isTooltipDisabled && headerValue,
              placement: 'top',
              arrow: false,
              componentsProps: {
                tooltip: {}
              }
            }}
            text={headerValue}
          />
        ) : (
          headerValue
        ),
        headerCell.getContext()
      );

  return (
    <StyledTableHeaderCell
      component="th"
      key={headerCell.id}
      ref={setNodeRef}
      columnId={headerCell.id}
      isDragging={isDragging}
      isPinned={isPinned}
      isLastLeftPinnedColumn={isPinned === 'left' && headerCell.column.getIsLastColumn('left')}
      leftStartPosition={headerCell.column.getStart('left')}
      actionsColumnSize={actionsColumnSize}
      hasDataSorting={hasDataSorting}
      style={{
        transform: !isPinned ? CSS.Translate.toString(transform) : ''
      }}
    >
      <Stack direction="row" alignItems="center">
        {!isDragging && headerCell.column.getCanPin() && (
          <Tooltip
            title={isPinned ? t('common:table.unpin') : t('common:table.pin')}
            placement="bottom"
            arrow
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <div
              className="pin-trigger"
              onClick={() => (headerCell.column.getIsPinned() ? headerCell.column.pin(false) : headerCell.column.pin('left'))}
            >
              <Icon
                fontSize="small"
                name={headerCell.column.getIsPinned() ? EIcon.PinLeft : EIcon.Pin}
                htmlColor={headerCell.column.getIsPinned() ? theme.palette.warning[400] : undefined}
              />
            </div>
          </Tooltip>
        )}
        {headerCell.column.columnDef.meta?.headerPrefix?.()}
        <Button
          color="secondary"
          variant="text"
          disableRipple
          onClick={headerCell.column.getToggleSortingHandler()}
          className="content"
          endIcon={
            headerCell.column.getIsSorted() === 'desc' ? (
              <Icon name={EIcon.ArrowDown} />
            ) : (
              headerCell.column.getIsSorted() === 'asc' && <Icon name={EIcon.ArrowUp} />
            )
          }
        >
          {renderedHeaderValue}
        </Button>
      </Stack>

      <Stack direction="row" className="triggers">
        {headerCell.column.columnDef.meta?.tooltipInfo && (
          <Tooltip placement="top" arrow title={headerCell.column.columnDef.meta?.tooltipInfo}>
            <div className="info-trigger">
              <Icon fontSize="small" name={EIcon.InfoCircle} className="info-circle" />
            </div>
          </Tooltip>
        )}
        {!isPinned && enableColumnSorting && (
          <Tooltip title={t('common:table.drag')} placement="bottom" arrow enterDelay={1000} enterNextDelay={1000}>
            <div className="drag-trigger">
              <Icon fontSize="small" name={EIcon.Hand} {...attributes} {...listeners} />
            </div>
          </Tooltip>
        )}
      </Stack>
      {!isDragging && headerCell.column.getCanResize() && (
        <Tooltip title={t('common:table.resize')} placement="bottom" arrow enterDelay={1000} enterNextDelay={1000}>
          <div
            {...{
              onDoubleClick: () => headerCell.column.resetSize(),
              onMouseDown: headerCell.getResizeHandler(),
              onTouchStart: headerCell.getResizeHandler(),
              className: `resizer ${headerCell.column.getIsResizing() ? 'isResizing' : ''}`
            }}
          />
        </Tooltip>
      )}
    </StyledTableHeaderCell>
  );
}

export default memo(TableHeaderCell) as typeof TableHeaderCell;
